<template>
   <v-dialog v-model="show" max-width="550px" persistent>
      <v-layout align-start>
         <v-flex xs12>
            <v-snackbar
               v-model="snackbar"
               :color="snackColor"
               :right="true"
               :bottom="true"
               :timeout="4000"
            >
               {{ snackText }}
               <template #action="{ attrs }">
                  <v-btn
                     color="white"
                     text
                     v-bind="attrs"
                     @click="snackbar = false"
                  >
                     Cerrar
                  </v-btn>
               </template>
            </v-snackbar>

            <v-card elevation="0">
               <v-toolbar color="primary" dark>
                  <v-toolbar-title style="font-weight: 500">
                     <span v-if="mode == 'post'">
                        {{ registrar ? 'Registro' : 'Identificación' }}
                     </span>
                     <span v-if="mode == 'put'"> Complete su registro </span>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
               </v-toolbar>
               <v-form
                  ref="formIdenti"
                  v-model="validLogin"
                  lazy-validation
                  mt-12
               >
                  <!-- DIALOG PARA IDENTIFICARSE -->
                  <v-card v-if="!registrar && mode == 'post'">
                     <v-card-text class="text--primary">
                        <v-container grid-list-md>
                           <v-layout wrap>
                              <v-flex
                                 xs12
                                 class="font-weight-medium text-justify"
                                 :style="
                                    $vuetify.breakpoint.smAndUp
                                       ? 'font-size: 16px;'
                                       : 'font-size: 15px;'
                                 "
                              >
                                 <span text-subtitle-1>
                                    Por favor, seleccione el tipo de carnet con
                                    el que desea identificarse:
                                 </span>
                              </v-flex>
                              <v-flex xs12>
                                 <v-radio-group
                                    v-model="radioTipoCarnet"
                                    column
                                    @change="clearCarnet"
                                 >
                                    <v-radio
                                       color="primary"
                                       value="checkCarnet"
                                    >
                                       <template #label>
                                          <div>
                                             <div
                                                :class="
                                                   radioTipoCarnet ==
                                                   'checkCarnet'
                                                      ? ' colorPrimary font-weight-medium'
                                                      : 'font-weight-medium'
                                                "
                                             >
                                                Carnet externo
                                             </div>
                                             <div>
                                                Personas externas y alumnos de
                                                Colegio Madre de la Iglesia
                                             </div>
                                          </div>
                                       </template>
                                    </v-radio>
                                    <v-radio
                                       class="mt-4"
                                       color="primary"
                                       value="checkCodigo"
                                    >
                                       <template #label>
                                          <div>
                                             <div
                                                :class="
                                                   radioTipoCarnet ==
                                                   'checkCodigo'
                                                      ? ' colorPrimary font-weight-medium'
                                                      : 'font-weight-medium'
                                                "
                                             >
                                                Código de idiomas
                                             </div>
                                             <div>
                                                Alumnos externos de niveles de
                                                idiomas
                                             </div>
                                          </div>
                                       </template>
                                    </v-radio>
                                 </v-radio-group>
                              </v-flex>
                           </v-layout>
                           <v-layout wrap>
                              <v-flex xs12>
                                 <v-text-field
                                    v-model="carnet"
                                    v-mask="
                                       radioTipoCarnet == 'checkCarnet'
                                          ? '####-AA-###'
                                          : '####-####'
                                    "
                                    :rules="carnetRules"
                                    color="primary"
                                    :label="
                                       radioTipoCarnet == 'checkCarnet'
                                          ? 'Carnet'
                                          : 'Código de idiomas'
                                    "
                                    :placeholder="
                                       radioTipoCarnet == 'checkCarnet'
                                          ? '0000-AA-000'
                                          : '0000-0000'
                                    "
                                    @keypress.enter="save"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 text-subtitle-1>
                                 ¿Olvidó su código de carnet o código de
                                 idiomas? Por favor envíe un correo a la
                                 siguiente dirección
                                 <a :href="'mailto:' + correoDev">
                                    {{ correoDev }}</a
                                 >.
                              </v-flex>
                              <v-flex xs12 text-subtitle-1>
                                 ¿Aún no cuenta con un registro?
                                 <a href="#" @click="registrar = true"
                                    >Registrarse</a
                                 >.
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </v-card-text>

                     <v-card-actions
                        style="
                           margin-top: -40px;
                           margin-right: 20px;
                           padding-bottom: 20px;
                        "
                     >
                        <v-spacer></v-spacer>
                        <v-btn
                           color="primary white--text"
                           :disabled="!validLogin || disableEnviar"
                           @click.native="save"
                        >
                           Identificarse
                        </v-btn>
                     </v-card-actions>
                  </v-card>
               </v-form>

               <v-form
                  :key="keyRegistro"
                  ref="formRegistro"
                  v-model="validRegistro"
                  lazy-validation
                  mt-12
               >
                  <!-- DIALOG PARA REGISTRARSE -->
                  <v-card v-if="registrar && mode == 'post'">
                     <v-card-text class="text--primary">
                        <v-container grid-list-md>
                           <v-layout wrap>
                              <v-flex
                                 xs12
                                 class="font-weight-medium text-justify"
                                 :style="
                                    $vuetify.breakpoint.smAndUp
                                       ? 'font-size: 16px;'
                                       : 'font-size: 15px;'
                                 "
                              >
                                 <span>
                                    <span class="colorPrimary"
                                       >IMPORTANTE:</span
                                    >
                                    Usted se está registrando en la aplicación
                                    de Colecturía para Usuarios Externos de la
                                    sede de UNICAES
                                    {{
                                       sede == 'IL' ? 'ILOBASCO' : 'SANTA ANA'
                                    }}.
                                 </span>
                                 <br />
                                 <br />
                                 <p style="color: black">
                                    Por favor, ingrese la información que se le
                                    solicita a continuación. En caso de no
                                    poseer DUI o NIT, deberá introducir los
                                    datos del responsable legal.
                                 </p>
                                 <br />
                                 <p>
                                    <span class="colorPrimary"
                                       >Programa de Idiomas:</span
                                    >
                                    Por favor, ingrese los datos de la persona
                                    que va a cursar el módulo.
                                 </p>
                              </v-flex>
                           </v-layout>
                           <v-layout wrap>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="nombres"
                                    :rules="nombresRules"
                                    color="primary"
                                    label="Nombres"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="apellidos"
                                    :rules="apellidosRules"
                                    color="primary"
                                    label="Apellidos"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-if="!checkExtranjero"
                                    v-model="dui"
                                    v-mask="'########-#'"
                                    :rules="duiRules"
                                    color="primary"
                                    label="DUI"
                                 ></v-text-field>
                                 <v-text-field
                                    v-else
                                    v-model="dui"
                                    :rules="identificacionRules"
                                    color="primary"
                                    label="N° de identificación"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-if="!checkExtranjero"
                                    v-model="nit"
                                    v-mask="'####-######-###-#'"
                                    :rules="nitRules"
                                    color="primary"
                                    label="NIT"
                                 ></v-text-field>
                                 <v-text-field
                                    v-else
                                    v-mask="'####-######-###-#'"
                                    color="primary"
                                    label="NIT (Opcional)"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="email"
                                    :rules="emailRules"
                                    color="primary"
                                    label="Email"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-if="!checkExtranjero"
                                    v-model="telefono"
                                    v-mask="'####-####'"
                                    :rules="telefonoRules"
                                    color="primary"
                                    label="Teléfono"
                                 ></v-text-field>
                                 <v-text-field
                                    v-else
                                    v-model="telefono"
                                    :rules="telefonoExtranjero"
                                    color="primary"
                                    label="Teléfono"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm12>
                                 <v-text-field
                                    v-model="direccion"
                                    :rules="direccionRules"
                                    color="primary"
                                    label="Dirección"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12>
                                 <v-layout justify-left align-left>
                                    <v-checkbox
                                       v-model="checkExtranjero"
                                       label="Soy extranjero"
                                       color="primary"
                                    ></v-checkbox>
                                 </v-layout>
                              </v-flex>
                              <v-flex xs12 text-subtitle-1>
                                 ¿Ya cuentas con un registro?
                                 <a href="#" @click="registrar = false">
                                    Identificarse</a
                                 >.
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </v-card-text>

                     <v-card-actions
                        style="
                           margin-top: -40px;
                           margin-right: 20px;
                           padding-bottom: 20px;
                        "
                     >
                        <v-spacer></v-spacer>
                        <v-btn
                           color="primary white--text"
                           :disabled="!validRegistro || disableEnviar"
                           @click.native="save"
                        >
                           Registrarse
                        </v-btn>
                     </v-card-actions>
                  </v-card>
               </v-form>

               <v-form
                  ref="formActualizar"
                  v-model="validUpdate"
                  lazy-validation
                  mt-12
               >
                  <!-- DIALOG PARA ACTUALIZAR INFO FALTANTE -->
                  <v-card v-if="mode == 'put'">
                     <v-card-text class="text--primary">
                        <v-container grid-list-md>
                           <v-layout wrap>
                              <v-flex
                                 xs12
                                 class="font-weight-medium text-justify"
                                 :style="
                                    $vuetify.breakpoint.smAndUp
                                       ? 'font-size: 16px;'
                                       : 'font-size: 15px;'
                                 "
                              >
                                 <span style="color: black">
                                    Por favor complete la información que se le
                                    solicita a continuación. De no poseer DUI o
                                    NIT, deberá introducir los datos del
                                    responsable legal a su cargo.
                                 </span>
                              </v-flex>
                           </v-layout>
                           <v-layout wrap>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="nombres"
                                    :rules="nombresRules"
                                    color="primary"
                                    label="Nombres"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="apellidos"
                                    :rules="apellidosRules"
                                    color="primary"
                                    label="Apellidos"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="dui"
                                    v-mask="'########-#'"
                                    :rules="duiRules"
                                    color="primary"
                                    label="DUI"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="nit"
                                    v-mask="'####-######-###-#'"
                                    :rules="nitRules"
                                    color="primary"
                                    label="NIT"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="email"
                                    :rules="emailRules"
                                    color="primary"
                                    label="Email"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="telefono"
                                    v-mask="'####-####'"
                                    :rules="telefonoRules"
                                    color="primary"
                                    label="Teléfono"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm12>
                                 <v-text-field
                                    v-model="direccion"
                                    :rules="direccionRules"
                                    color="primary"
                                    label="Dirección"
                                 ></v-text-field>
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </v-card-text>

                     <v-card-actions
                        style="
                           margin-top: -40px;
                           margin-right: 20px;
                           padding-bottom: 20px;
                        "
                     >
                        <v-spacer></v-spacer>
                        <v-btn
                           color="primary white--text"
                           :disabled="!validUpdate"
                           @click.native="save"
                        >
                           Actualizar
                        </v-btn>
                     </v-card-actions>
                  </v-card>
               </v-form>
            </v-card>
         </v-flex>
      </v-layout>
   </v-dialog>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import { mask } from 'vue-the-mask'

export default {
   name: 'ClienteForm',
   directives: {
      mask
   },
   props: {
      mode: {
         type: String,
         default: 'post'
      },
      value: Boolean
   },
   data: () => ({
      // Variables
      sede: process.env.VUE_APP_SEDE,
      correoDev: process.env.VUE_APP_CORREO_DEV,
      radioTipoCarnet: 'checkCarnet',
      disableEnviar: false,
      dialogBecario: false,
      dialogEliminar: false,
      checkExtranjero: false,
      pagination: {},
      result: [],
      becas: [],
      becaNombre: '',
      validLogin: true,
      validRegistro: true,
      validUpdate: true,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',
      ClienteId: '',
      carnet: '',
      nombres: '',
      apellidos: '',
      email: '',
      direccion: '',
      telefono: '',
      dui: '',
      nit: '',
      registrar: false,
      keyRegistro: 1,

      // Reglas de validacion de formularios
      nombresRules: [(v) => !!v || 'Ingrese sus nombres'],
      apellidosRules: [(v) => !!v || 'Ingrese sus apellidos'],
      carnetRules: [(v) => !!v || 'Ingrese su código de carnet'],
      duiRules: [
         (v) => !!v || 'Ingrese su DUI',
         (v) => (v && v.length == 10) || 'Termine de escribir su DUI'
      ],
      identificacionRules: [
         (v) => !!v || 'Ingrese un número de identificación'
      ],
      nitRules: [
         (v) => !!v || 'Ingrese su NIT',
         (v) => (v && v.length == 17) || 'Termine de escribir su NIT'
      ],
      telefonoRules: [
         (v) => !!v || 'Ingrese su número de teléfono',
         (v) => (v && v.length == 9) || 'Termine de escribir su teléfono'
      ],
      telefonoExtranjero: [(v) => !!v || 'Ingrese su número de teléfono'],
      emailRules: [
         (v) => !!v || 'Ingrese su dirección e-mail',
         (v) =>
            v === '' ||
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
               v
            ) ||
            'Formato incorrecto de e-mail'
      ],
      direccionRules: [(v) => !!v || 'Ingrese una dirección']
   }),
   computed: {
      ...authComputed,
      show: {
         get() {
            if (this.value && this.publicUserLoggedIn) {
               this.nombres = this.publicUser.nombres
               this.apellidos = this.publicUser.apellidos
            }
            return this.value
         },
         set(value) {
            this.$emit('input', value)
         }
      }
   },
   watch: {
      checkExtranjero() {
         this.dui = ''
         this.$refs.formRegistro.resetValidation()
         this.keyRegistro = this.keyRegistro == 1 ? 2 : 1
      }
   },
   created() {},
   methods: {
      clearCarnet() {
         setTimeout(() => {
            this.$refs.formIdenti.resetValidation()
            this.carnet = ''
         }, 50)
      },
      close() {
         this.show = false
      },
      save() {
         if (
            (this.mode == 'post' &&
               !this.registrar &&
               this.$refs.formIdenti.validate()) ||
            (this.mode == 'post' &&
               this.registrar &&
               this.$refs.formRegistro.validate()) ||
            (this.mode == 'put' && this.$refs.formActualizar.validate())
         ) {
            this.disableEnviar = true
            if (this.mode == 'post') {
               if (!this.registrar) {
                  // Revisar si ya cuenta con un registro
                  axios
                     .get('api/cliente/' + this.carnet)
                     .then((response) => {
                        if (response.data) {
                           // Retornar identidad a componente padre
                           this.$store.dispatch('login', response.data)
                           this.close()
                        }
                     })
                     .catch(() => {
                        this.snackText =
                           'No se encontraron registros con su código de carnet.'
                        this.snackColor = 'snackError'
                        this.snackbar = true
                        this.disableEnviar = false
                     })
               } else {
                  // Registrar
                  axios
                     .post('api/Cliente', {
                        dui: this.dui,
                        nit: this.nit,
                        nombres: this.nombres
                           .replace(/  +/g, ' ')
                           .trim()
                           .toUpperCase(),
                        apellidos: this.apellidos
                           .replace(/  +/g, ' ')
                           .trim()
                           .toUpperCase(),
                        direccion: this.direccion,
                        email: this.email,
                        telefono: this.telefono.replace('-', ''),
                        activo: true,
                        extranjero: this.checkExtranjero
                     })
                     .then((response) => {
                        this.snackText = 'Datos registrados exitosamente!'
                        this.snackColor = 'snackSuccess'
                        this.snackbar = true
                        setTimeout(() => {
                           this.disableEnviar = false
                           this.registrar = false
                        }, 2500)
                        // Retornar identidad a componente padre
                        this.$store.dispatch('login', response.data)
                        this.close()
                     })
                     .catch(() => {
                        this.snackText = 'Algo ha ido mal...'
                        this.snackColor = 'snackError'
                        this.snackbar = true
                        this.disableEnviar = false
                     })
               }
            } else {
               // Actualizar información
               axios
                  .put('api/Cliente/' + this.publicUser.carnet, {
                     dui: this.dui,
                     nit: this.nit,
                     nombres: this.nombres
                        .replace(/  +/g, ' ')
                        .trim()
                        .toUpperCase(),
                     apellidos: this.apellidos
                        .replace(/  +/g, ' ')
                        .trim()
                        .toUpperCase(),
                     direccion: this.direccion,
                     email: this.email,
                     telefono: this.telefono.replace('-', ''),
                     activo: true,
                     extranjero: false
                  })
                  .then((response) => {
                     this.snackText = 'Datos registrados exitosamente!'
                     this.snackColor = 'snackSuccess'
                     this.snackbar = true
                     setTimeout(() => {
                        this.disableEnviar = false
                        this.registrar = false
                     }, 2500)
                     // Retornar identidad a componente padre
                     this.$store.dispatch('login', response.data)
                     this.close()
                  })
                  .catch(() => {
                     this.snackText = 'Algo ha ido mal...'
                     this.snackColor = 'snackError'
                     this.snackbar = true
                     this.disableEnviar = false
                  })
            }
         }
      },
      // Limpiar formularios y componentes
      reset() {
         try {
            this.$refs.formIdenti.reset()
         } catch (error) {
            //Do nothing
         }
         try {
            this.$refs.formRegistro.reset()
         } catch (error) {
            //Do nothing
         }
         try {
            this.$refs.formActualizar.reset()
         } catch (error) {
            //Do nothing
         }
         this.result = ''
         this.nombres = ''
         this.apellidos = ''
         this.email = ''
         this.direccion = ''
         this.dui = ''
         this.nit = ''
      }
   }
}
</script>
