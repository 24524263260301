<template>
   <v-layout align-start>
      <v-flex
         xs12
         :style="
            $vuetify.breakpoint.xlOnly
               ? 'padding-right: 10%; padding-left: 10%'
               : ''
         "
      >
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="5000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <!-- PARAMETROS SERFINSA -->
         <v-text-field
            id="TokenSerfinsa"
            v-model="tokenSerfinsa"
            style="display: none"
            readonly
            disabled
         ></v-text-field>
         <v-text-field
            id="IdTransaccion"
            v-model="idTransaccion"
            style="display: none"
            readonly
            disabled
         ></v-text-field>
         <v-text-field
            id="MontroTransaccion"
            v-model="totalPagar"
            style="display: none"
            readonly
            disabled
         ></v-text-field>
         <!-- Serfinsa btPagar trigger -->
         <v-btn id="btPagar" href="#" style="display: none"></v-btn>

         <!-- DIALOG LOADING ESPERA RESPUESTA POPUP -->
         <v-dialog v-model="dialogEsperaPago" persistent width="333">
            <v-card :color="dialogEsperaColor" dark class="pt-5 pb-2">
               <v-card-text style="color: white" class="text-justify">
                  <strong>{{ dialogEsperaMessage }}</strong>
                  <br /><br />
                  {{ dialogEsperaSubMessage }}

                  <div
                     v-if="
                        dialogEsperaMessage ==
                        'Esperando respuesta de ventana de pago.'
                     "
                     class="text-center"
                  >
                     <a
                        style="
                           color: white;
                           font-weight: bold;
                           font-size: 17px;
                           text-decoration: underline;
                        "
                        @click="refreshPage"
                     >
                        <br />
                        Cancelar transacción
                        <br /><br />
                     </a>
                  </div>

                  <v-progress-linear
                     indeterminate
                     color="white"
                     class="mb-0"
                  ></v-progress-linear>
               </v-card-text>
            </v-card>
         </v-dialog>

         <!-- DIALOG LOGIN CLIENTE -->
         <ClienteForm
            ref="ClienteForm"
            v-model="dialogClienteInfo"
            :mode="modeClienteInfo"
         />

         <!-- DIALOG REINICIAR NIVEL IDIOMAS -->
         <v-dialog v-model="dialogReiniciar" persistent width="350">
            <v-card class="py-6">
               <v-card-title
                  class="text-h6 py-4 text-justify"
                  style="word-break: normal"
               >
                  Esta por reiniciar el progreso de su módulo de idiomas al
                  nivel 1. ¿Desea continuar?
               </v-card-title>
               <v-card-actions class="d-flex justify-end">
                  <v-btn
                     class="mr-2"
                     outlined
                     color="primary"
                     @click="dialogReiniciar = false"
                     >Cancelar</v-btn
                  >
                  <v-btn color="primary" dark @click="reiniciarNivel"
                     >Si, reiniciar</v-btn
                  >
               </v-card-actions>
            </v-card>
         </v-dialog>

         <!-- DIALOG REGISTRO MODULO IDIOMAS -->
         <RegistroIdiomas
            ref="RegistroIdiomas"
            v-model="dialogRegistroIdiomas"
            :carnet-externo="publicUser != null ? publicUser.carnet : ''"
            :nombres="
               publicUser != null ? publicUser.nombres.toUpperCase() : ''
            "
            :apellidos="
               publicUser != null ? publicUser.apellidos.toUpperCase() : ''
            "
            :telefono="publicUser != null ? publicUser.telefono : ''"
            externo
            @carnet-generado="showCarnetGenerado($event)"
         />

         <!-- DIALOG DETALLE DE PAGO DE CUOTA-->
         <v-dialog v-model="dialogPagoCuota" eager persistent max-width="600px">
            <v-form>
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <v-flex xs12 class="text-h5 font-weight-medium">
                                 Detalles pago de cuota # {{ numeroCuota }} -
                                 {{ periodoLectivo }}
                              </v-flex>
                              <v-card class="borderDetallesPrimary mt-2">
                                 <v-container>
                                    <v-layout wrap>
                                       <v-flex xs12>
                                          <v-data-table
                                             hide-default-footer
                                             :items-per-page="-1"
                                             :footer-props="{
                                                itemsPerPageOptions: [-1],
                                                itemsPerPageText:
                                                   'Filas por página:'
                                             }"
                                             :headers="headersAranceles"
                                             :items="filteredCuotaArancel"
                                          >
                                             <template
                                                #item.descripcion="{ item }"
                                             >
                                                {{
                                                   item.descripcion.replace(
                                                      '_',
                                                      ' '
                                                   )
                                                }}
                                             </template>
                                             <template
                                                #item.precioUnitario="{ item }"
                                             >
                                                $
                                                {{
                                                   numFormatIntl(
                                                      Number(
                                                         item.precioUnitario
                                                      ) +
                                                         Number(
                                                            item.montoImpuesto
                                                         )
                                                   )
                                                }}
                                             </template>
                                          </v-data-table>
                                       </v-flex>
                                    </v-layout>
                                 </v-container>
                              </v-card>
                              <v-flex
                                 v-if="cuotaTipoBeca == null"
                                 xs12
                                 class="font-weight-medium text-h6 text-center mt-1"
                              >
                                 Total a pagar: $ {{ totalPagar }}
                              </v-flex>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>
                  <v-card-actions
                     style="margin-top: -20px; padding-bottom: 20px"
                  >
                     <v-layout>
                        <v-spacer></v-spacer>
                        <v-btn
                           color="gray"
                           text
                           class="mr-1"
                           width="150"
                           @click.native="closePagoCuota"
                           >Cancelar</v-btn
                        >
                        <v-btn
                           href="#"
                           color="primary text--white"
                           class="ml-1"
                           width="150"
                           :disabled="pagosDeshabilitados"
                           @click="pagosDeshabilitados ? false : initPago()"
                           >Pagar
                        </v-btn>
                        <v-spacer></v-spacer>
                     </v-layout>
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG DETALLE DE PAGO DE CUOTA MULTIPLE -->
         <v-dialog
            v-model="dialogPagoCuotaMultiple"
            eager
            persistent
            max-width="600px"
         >
            <v-form>
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <v-flex xs12 class="text-h5 font-weight-medium">
                                 Opción de pago multiple
                              </v-flex>
                              <v-flex xs12 class="text-h6">
                                 Detalles de pago
                              </v-flex>
                              <v-card class="borderDetallesPrimary mt-2">
                                 <v-container>
                                    <v-layout wrap>
                                       <v-flex xs12>
                                          <v-data-table
                                             hide-default-footer
                                             :items-per-page="-1"
                                             :footer-props="{
                                                itemsPerPageOptions: [-1],
                                                itemsPerPageText:
                                                   'Filas por página:'
                                             }"
                                             :headers="headersAranceles"
                                             :items="cuotasPagoMultiple"
                                          >
                                             <template
                                                #item.precioUnitario="{ item }"
                                             >
                                                $
                                                {{
                                                   numFormatIntl(
                                                      Number(
                                                         item.precioUnitario
                                                      )
                                                   )
                                                }}
                                             </template>
                                          </v-data-table>
                                       </v-flex>
                                    </v-layout>
                                 </v-container>
                              </v-card>
                              <v-flex
                                 xs12
                                 class="font-weight-medium text-h6 text-center mt-1"
                              >
                                 Total a pagar: $
                                 {{ numFormatIntl(totalPagar) }}
                              </v-flex>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>
                  <v-card-actions
                     style="margin-top: -20px; padding-bottom: 20px"
                  >
                     <v-layout>
                        <v-spacer></v-spacer>
                        <v-btn
                           color="gray"
                           text
                           class="mr-1"
                           width="150"
                           @click.native="closePagoCuotaMultiple"
                           >Cancelar</v-btn
                        >
                        <v-btn
                           href="#"
                           color="primary text--white"
                           class="ml-1"
                           width="150"
                           :disabled="pagosDeshabilitados"
                           @click="pagosDeshabilitados ? false : initPago()"
                           >Pagar
                        </v-btn>
                        <v-spacer></v-spacer>
                     </v-layout>
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG DETALLE DE PAGO DE ARANCEL-->
         <v-dialog
            v-model="dialogPagoArancel"
            eager
            persistent
            max-width="600px"
         >
            <v-form
               ref="formPagoArancel"
               v-model="validPagoArancel"
               lazy-validation
            >
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <v-flex xs12 class="text-h5 font-weight-medium">
                                 Detalles pago de arancel
                              </v-flex>
                              <v-card class="borderDetallesPrimary mt-2">
                                 <v-container>
                                    <v-layout wrap>
                                       <v-flex xs12>
                                          <v-data-table
                                             hide-default-footer
                                             :items-per-page="-1"
                                             :footer-props="{
                                                itemsPerPageOptions: [-1],
                                                itemsPerPageText:
                                                   'Filas por página:'
                                             }"
                                             :headers="headersAranceles"
                                             :items="detallePagoArancel"
                                          >
                                          </v-data-table>
                                       </v-flex>
                                    </v-layout>
                                 </v-container>
                              </v-card>
                              <v-flex xs12 mt-4>
                                 <p
                                    class="text-subtitle-1 font-weight-medium text-justify"
                                 >
                                    <span class="colorPrimary"
                                       >Descripción:
                                    </span>
                                    {{ arancelDescripcion }}
                                 </p>
                              </v-flex>
                              <v-flex v-if="arancelReqFacultad" xs12 mt-4>
                                 <p class="text-h6 font-weight-medium">
                                    Seleccione la carrera de la cual egresó:
                                 </p>
                                 <v-autocomplete
                                    v-model="selectCarreraUniversitaria"
                                    :items="cmbCarreraArancel"
                                    :rules="rulesFacultad"
                                    color="primary"
                                    item-text="nombre"
                                    item-value="carreraUniversitariaId"
                                    label="Carrera Universitaria"
                                 >
                                 </v-autocomplete>
                              </v-flex>
                              <v-flex
                                 v-if="arancelCantidad"
                                 xs12
                                 mt-4
                                 :style="
                                    $vuetify.breakpoint.smAndUp
                                       ? 'margin: 0% 25%;'
                                       : ''
                                 "
                              >
                                 <v-input-number
                                    :value="itemCantidad"
                                    label="Cantidad"
                                    :min="1"
                                    @input="itemCantidad = $event"
                                 ></v-input-number>
                              </v-flex>
                              <v-flex
                                 xs12
                                 class="font-weight-medium text-h6 text-center mt-1"
                              >
                                 Total a pagar: $ {{ totalPagar }}
                              </v-flex>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>
                  <v-card-actions
                     style="margin-top: -20px; padding-bottom: 20px"
                  >
                     <v-layout>
                        <v-spacer></v-spacer>
                        <v-btn
                           color="gray"
                           text
                           class="mr-1"
                           width="150"
                           @click.native="closePagoArancel"
                           >Cancelar</v-btn
                        >
                        <v-btn
                           href="#"
                           color="primary text--white"
                           class="ml-1"
                           width="150"
                           :disabled="pagosDeshabilitados || !validPagoArancel"
                           @click="pagosDeshabilitados ? false : initPago()"
                           >Pagar
                        </v-btn>
                        <v-spacer></v-spacer>
                     </v-layout>
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG DETALLE DE PAGO DE IDIOMAS -->
         <v-dialog
            v-model="dialogPagoIdioma"
            eager
            persistent
            max-width="600px"
         >
            <v-form
               ref="formPagoIdioma"
               v-model="validPagoIdioma"
               lazy-validation
            >
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <v-flex xs12 class="text-h5 font-weight-medium">
                                 Detalles pago de idiomas
                              </v-flex>
                              <v-card class="borderDetallesPrimary mt-2">
                                 <v-container>
                                    <v-layout wrap>
                                       <v-flex xs12 mt-4>
                                          <p
                                             class="text-subtitle-1 font-weight-medium text-justify"
                                          >
                                             <span class="colorPrimary"
                                                >Idioma:
                                             </span>
                                             <span>
                                                {{ idiomaNombre }}
                                             </span>
                                             <br />
                                             <span class="colorPrimary"
                                                >Nivel:
                                             </span>
                                             <span>
                                                {{ intToRoman(idiomaNivel) }}
                                             </span>
                                             <br />
                                             <span class="colorPrimary"
                                                >Programa:
                                             </span>
                                             <span>
                                                {{ idiomaProgramaDesc }}
                                             </span>
                                             <v-flex
                                                v-if="recargoIdiomas > 0"
                                                class="pl-0"
                                             >
                                                <span class="colorPrimary"
                                                   >Recargo por pago
                                                   extemporáneo:
                                                </span>
                                                <span>
                                                   ${{ recargoIdiomas }}
                                                </span>
                                             </v-flex>
                                          </p>
                                       </v-flex>
                                    </v-layout>
                                 </v-container>
                              </v-card>
                              <v-flex v-if="idiomaVencido" xs12 class="mt-4">
                                 <v-layout
                                    wrap
                                    fill-height
                                    align-center
                                    :class="
                                       $vuetify.breakpoint.smAndUp
                                          ? 'px-6'
                                          : 'px-4 py-4'
                                    "
                                 >
                                    <v-flex
                                       xs12
                                       :class="
                                          $vuetify.breakpoint.smAndUp
                                             ? 'text-center'
                                             : 'text-center'
                                       "
                                    >
                                       <v-icon size="35" color="primary">
                                          fa-info-circle
                                       </v-icon>
                                    </v-flex>
                                    <v-flex
                                       xs12
                                       text-justify
                                       :class="
                                          $vuetify.breakpoint.smAndUp
                                             ? 'pt-2 pl-4'
                                             : 'pt-2'
                                       "
                                    >
                                       <p
                                          class="font-weight-medium colorPrimary"
                                          style="
                                             margin-bottom: 5px;
                                             font-size: 18px;
                                             line-height: 1.6;
                                          "
                                       >
                                          Estimado alumno/a. Ya han transcurrido
                                          {{ idiomaMesesDesdeUltimoModulo }}
                                          meses desde la finalización de su
                                          último módulo de idiomas, actualmente
                                          el limite de inactividad permitido es
                                          de
                                          {{ idiomaLimiteMesesInacividad }}
                                          meses. <br /><br />
                                          En esta situación puede optar por
                                          realizar exámen de ubicación el cual
                                          puede cancelar en la sección de pagos
                                          de aranceles ó reiniciar su progreso
                                          al nivel 1 haciendo clic en el
                                          siguiente botón.
                                       </p>
                                    </v-flex>
                                    <v-flex xs12 class="d-flex justify-center">
                                       <v-btn
                                          color="primary"
                                          @click="dialogReiniciar = true"
                                          >Reiniciar nivel</v-btn
                                       >
                                    </v-flex>
                                 </v-layout>
                              </v-flex>
                              <v-flex
                                 v-if="nivelDeshabilitado"
                                 xs12
                                 class="mt-4"
                              >
                                 <v-layout
                                    wrap
                                    fill-height
                                    align-center
                                    :class="
                                       $vuetify.breakpoint.smAndUp
                                          ? 'px-6'
                                          : 'px-4 py-4'
                                    "
                                 >
                                    <v-flex
                                       xs12
                                       sm1
                                       :class="
                                          $vuetify.breakpoint.smAndUp
                                             ? 'text-left'
                                             : 'text-center'
                                       "
                                    >
                                       <v-icon size="35" color="primary">
                                          fa-info-circle
                                       </v-icon>
                                    </v-flex>
                                    <v-flex
                                       xs12
                                       sm11
                                       text-justify
                                       :class="
                                          $vuetify.breakpoint.smAndUp
                                             ? 'pt-2 pl-4'
                                             : 'pt-2'
                                       "
                                    >
                                       <p
                                          class="font-weight-medium colorPrimary"
                                          style="
                                             margin-bottom: 5px;
                                             font-size: 18px;
                                             line-height: 1.6;
                                          "
                                       >
                                          El nivel que desea cursar no se
                                          impartirá en esta entrega para el
                                          programa que ha seleccionado, por
                                          favor intente con otro programa.
                                       </p>
                                    </v-flex>
                                 </v-layout>
                              </v-flex>
                              <v-flex v-if="cupoAgotado" xs12 class="mt-4">
                                 <v-layout
                                    wrap
                                    fill-height
                                    align-center
                                    :class="
                                       $vuetify.breakpoint.smAndUp
                                          ? 'px-6'
                                          : 'px-4 py-4'
                                    "
                                 >
                                    <v-flex
                                       xs12
                                       sm1
                                       :class="
                                          $vuetify.breakpoint.smAndUp
                                             ? 'text-left'
                                             : 'text-center'
                                       "
                                    >
                                       <v-icon size="35" color="primary">
                                          fa-info-circle
                                       </v-icon>
                                    </v-flex>
                                    <v-flex
                                       xs12
                                       sm11
                                       text-justify
                                       :class="
                                          $vuetify.breakpoint.smAndUp
                                             ? 'pt-2 pl-4'
                                             : 'pt-2'
                                       "
                                    >
                                       <p
                                          class="font-weight-medium colorPrimary"
                                          style="
                                             margin-bottom: 5px;
                                             font-size: 18px;
                                             line-height: 1.6;
                                          "
                                       >
                                          Estimado alumno/a, el cupo para el
                                          nivel 1 del programa que usted ha
                                          seleccionado se encuentra agotado en
                                          estos momentos.
                                       </p>
                                    </v-flex>
                                 </v-layout>
                              </v-flex>
                              <v-flex xs12 mt-4>
                                 <p class="text-h6 font-weight-medium">
                                    Seleccione el programa en el que desea
                                    continuar:
                                 </p>
                                 <v-autocomplete
                                    v-model="selectPrograma"
                                    :items="cmbProgramas"
                                    :rules="rulesPrograma"
                                    :disabled="idiomaVencido"
                                    color="primary"
                                    item-text="nombre"
                                    item-value="programaId"
                                    label="Programas activos disponibles"
                                 >
                                 </v-autocomplete>
                              </v-flex>
                              <v-flex
                                 xs12
                                 class="font-weight-medium text-h6 text-center mt-1"
                              >
                                 Total a pagar: $
                                 {{
                                    totalPagar === ''
                                       ? '0.00'
                                       : numFormatIntl(totalPagar)
                                 }}
                              </v-flex>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>
                  <v-card-actions
                     style="margin-top: -20px; padding-bottom: 20px"
                  >
                     <v-layout>
                        <v-spacer></v-spacer>
                        <v-btn
                           color="gray"
                           text
                           class="mr-1"
                           width="150"
                           @click.native="closePagoIdioma"
                        >
                           Cancelar
                        </v-btn>
                        <v-btn
                           href="#"
                           color="primary text--white"
                           class="ml-1"
                           width="150"
                           :disabled="
                              pagosIdiomasDeshabilitados ||
                              disablePagoIdioma ||
                              !validPagoIdioma ||
                              idiomaVencido
                           "
                           @click="
                              pagosIdiomasDeshabilitados ||
                              nivelDeshabilitado ||
                              idiomaVencido
                                 ? false
                                 : validatePagoIdiomas()
                           "
                           >Pagar
                        </v-btn>
                        <v-spacer></v-spacer>
                     </v-layout>
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG CARNET ACTUALIZADO -->
         <v-dialog
            v-model="dialogCarnetGenerado"
            eager
            persistent
            max-width="600px"
         >
            <v-card>
               <v-card-text class="text--primary">
                  <v-container grid-list-md>
                     <v-layout wrap>
                        <v-flex xs12>
                           <v-flex xs12 class="text-h5 font-weight-medium">
                              Carnet actualizado
                           </v-flex>
                           <v-flex
                              xs12
                              class="font-weight-medium text-justify py-8"
                              :style="
                                 $vuetify.breakpoint.smAndUp
                                    ? 'font-size: 16px;'
                                    : 'font-size: 15px;'
                              "
                           >
                              <span>
                                 Debido a tu reciente registro en un módulo de
                                 idiomas hemos actualizado tu carnet de usuario
                                 externo por tu nuevo
                                 <span class="colorPrimary"
                                    >código de idiomas</span
                                 >.

                                 <br /><br />
                                 Cuando desees realizar pagos para el módulo de
                                 idiomas en el que acabas de inscribirte deberás
                                 iniciar sesión con el código de idiomas:
                                 <span class="colorPrimary">{{
                                    nuevoCarnet
                                 }}</span
                                 >.
                              </span>
                           </v-flex>
                        </v-flex>
                     </v-layout>
                  </v-container>
               </v-card-text>
               <v-card-actions style="margin-top: -20px; padding-bottom: 20px">
                  <v-layout>
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary text--white"
                        width="150"
                        @click="closeCarnetGenerado"
                        >Entendido
                     </v-btn>
                     <v-spacer></v-spacer>
                  </v-layout>
               </v-card-actions>
            </v-card>
         </v-dialog>

         <!-- SI ESTA VERIFICADO -->
         <v-card v-if="mostrarMensaje" class="borderDetallesPrimary mx-8">
            <v-layout
               wrap
               fill-height
               align-center
               :class="$vuetify.breakpoint.smAndUp ? 'px-6' : 'px-4 py-4'"
            >
               <v-flex xs12 sm1 text-center>
                  <v-icon size="35" color="primary"> fa-info-circle </v-icon>
               </v-flex>
               <v-flex xs12 sm11 text-justify class="pt-2">
                  <span class="font-weight-bold colorPrimary">
                     AVISO IMPORTANTE:
                  </span>
                  <p class="font-weight-medium" style="margin-bottom: 10px">
                     {{ mensajeImportante }}
                  </p>
               </v-flex>
            </v-layout>
         </v-card>
         <v-stepper
            v-if="publicUserLoggedIn"
            v-model="stepNum"
            non-linear
            class="elevation-0"
         >
            <v-stepper-header v-if="false"> </v-stepper-header>

            <v-stepper-items>
               <!-- PANTALLA DE BIENVENIDA -->
               <v-stepper-content step="1">
                  <v-layout wrap text-center>
                     <v-flex xs12 class="mb-6">
                        <v-card
                           class="borderDetallesSecondary mx-2"
                           style="margin-top: 0px"
                        >
                           <v-layout
                              wrap
                              fill-height
                              align-center
                              :class="
                                 $vuetify.breakpoint.smAndUp
                                    ? 'px-6'
                                    : 'px-4 py-4'
                              "
                           >
                              <v-flex xs12 sm1 text-center>
                                 <v-icon size="35" color="orange darken-4"
                                    >fa-info-circle</v-icon
                                 >
                              </v-flex>
                              <v-flex xs12 sm11 text-justify class="pt-2">
                                 <span class="font-weight-bold">
                                    Estimado cliente:
                                 </span>
                                 <p
                                    class="font-weight-medium"
                                    style="margin-bottom: 10px"
                                 >
                                    Su código de carnet es:
                                    <a href="#"> {{ publicUser.carnet }}</a
                                    >. Con este código podrá identificarse la
                                    próxima vez que visite Colecturía de
                                    Usuarios Externos.
                                 </p>
                              </v-flex>
                           </v-layout>
                        </v-card>
                     </v-flex>
                     <v-flex xs12>
                        <v-img
                           src="/assets/logo-u700.png"
                           class="my-3"
                           contain
                           height="260"
                        ></v-img>
                     </v-flex>

                     <v-flex xs12 mb-4>
                        <h1 class="text-h3 font-weight-bold mb-3">
                           UNICAES <br />
                           Colecturía Usuarios Externos
                           <div v-if="sede == 'IL'">ILOBASCO</div>
                        </h1>
                     </v-flex>
                     <v-flex xs12 mb-4>
                        <span class="font-weight-bold text-h5 mb-3">
                           Bienvenido/a,
                        </span>
                        <span
                           class="colorPrimary font-weight-bold text-h5 mb-3"
                        >
                           {{
                              publicUser.nombres.toUpperCase() +
                              ' ' +
                              publicUser.apellidos.toUpperCase()
                           }}
                        </span>
                     </v-flex>
                     <v-flex xs12>
                        <span class="font-weight-bold text-h5 mb-3">
                           ¿Que tipo de pago deseas realizar?
                        </span>
                     </v-flex>
                     <v-flex xs12 class="mt-4 mb-8">
                        <v-btn
                           v-if="alumnoMadre"
                           color="primary"
                           x-large
                           width="250"
                           :class="
                              $vuetify.breakpoint.smAndUp ? 'mr-1 mt-2' : ''
                           "
                           @click="listarCuotas"
                        >
                           Pagos de cuotas
                        </v-btn>
                        <v-btn
                           color="primary"
                           x-large
                           width="250"
                           :class="
                              $vuetify.breakpoint.smAndUp
                                 ? 'ml-1 mr-1 mt-2'
                                 : 'mt-2'
                           "
                           @click="irPagoAranceles"
                        >
                           Pagos de aranceles
                        </v-btn>
                        <v-btn
                           v-if="!alumnoMadre"
                           color="primary"
                           :loading="loadingPagosIdiomas"
                           :disabled="loadingPagosIdiomas"
                           x-large
                           width="250"
                           :class="
                              $vuetify.breakpoint.smAndUp
                                 ? 'mr-1 ml-1 mt-2'
                                 : 'mt-2'
                           "
                           @click="irPagoIdiomas"
                        >
                           <template #loader>
                              <span>Cargando...</span>
                           </template>
                           Pagos de idiomas
                        </v-btn>
                        <v-btn
                           color="primary"
                           outlined
                           x-large
                           width="250"
                           :class="
                              $vuetify.breakpoint.smAndUp ? 'ml-1 mt-2' : 'mt-2'
                           "
                           @click="listarPagos"
                        >
                           Historial de pagos
                        </v-btn>
                     </v-flex>
                     <v-flex xs12>
                        <p class="text-h6 font-weight-bold">
                           Versión: {{ version }}
                        </p>
                     </v-flex>
                  </v-layout>
               </v-stepper-content>

               <!-- PAGOS DE CUOTAS DE TALONARIOS -->
               <v-stepper-content
                  step="2"
                  :style="
                     $vuetify.breakpoint.xsOnly
                        ? 'margin: 0px 0px; padding: 0px 0px;'
                        : ''
                  "
               >
                  <v-container fluid grid-list-lg class="white pt-0 mt-1">
                     <v-layout wrap>
                        <v-flex xs12>
                           <v-toolbar flat color="white">
                              <v-toolbar-title class="text-h5 font-weight-bold"
                                 >Pagos de cuotas</v-toolbar-title
                              >
                              <v-spacer></v-spacer>
                           </v-toolbar>
                        </v-flex>
                        <v-flex v-if="pagosDeshabilitados" xs12 ml-4>
                           <p class="colorPrimary font-weight-bold text-h6">
                              Sistema en mantenimiento, se han desactivado las
                              funciones de pago en línea.
                              <v-icon
                                 color="primary"
                                 style="margin-top: -5px"
                                 right
                                 size="30"
                              >
                                 fas fa-info-circle
                              </v-icon>
                           </p>
                        </v-flex>
                        <v-flex xs12>
                           <!-- INFORMACION ALUMNO -->
                           <v-layout wrap class="pl-4 pr-4">
                              <v-flex xs12 sm2 class="smallForm">
                                 <v-text-field
                                    v-model="publicUser.carnet"
                                    color="primary"
                                    label="Carnet"
                                    readonly
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm4 class="smallForm">
                                 <v-text-field
                                    v-model="alumno"
                                    label="Alumno"
                                    color="primary"
                                    readonly
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 class="smallForm">
                                 <v-text-field
                                    v-model="carreraNombre"
                                    label="Carrera"
                                    color="primary"
                                    readonly
                                 ></v-text-field>
                              </v-flex>
                           </v-layout>
                        </v-flex>
                        <v-flex xs12>
                           <v-card
                              class="borderDetallesPrimary"
                              style="margin-bottom: -15px"
                           >
                              <v-card-text
                                 class="text--primary font-weight-medium"
                                 :style="
                                    $vuetify.breakpoint.smAndUp
                                       ? 'font-size: 18px;'
                                       : 'font-size: 15px;'
                                 "
                              >
                                 <v-layout wrap fill-height align-center>
                                    <v-flex xs12 sm1 text-center>
                                       <v-icon size="35" color="primary"
                                          >fa-download</v-icon
                                       >
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                       <div class="font-weight-medium">
                                          Puedes descargar tu talonario completo
                                          para el ciclo
                                          {{ cicloActual }} en formato para
                                          imprimir haciendo clic en este botón.
                                       </div>
                                    </v-flex>
                                    <v-flex xs12 sm5>
                                       <v-btn
                                          block
                                          color="primary"
                                          @click="descargarTalonarioCompleto"
                                       >
                                          Descargar
                                       </v-btn>
                                    </v-flex>
                                 </v-layout>
                              </v-card-text>
                           </v-card>
                        </v-flex>
                        <v-flex xs12>
                           <v-card class="borderDetallesPrimary">
                              <v-card-text class="text--primary">
                                 <v-layout wrap mb-2>
                                    <v-flex xs12 sm6>
                                       <span class="text-h6">
                                          Estado de cuenta
                                       </span>
                                    </v-flex>
                                    <v-flex xs12 sm6 text-right>
                                       <v-btn
                                          v-if="cuotasSeleccionadas.length > 1"
                                          color="primary"
                                          :disabled="
                                             pagosDeshabilitados ||
                                             debeComprobantes
                                          "
                                          @click="
                                             pagosDeshabilitados ||
                                             debeComprobantes
                                                ? false
                                                : showPagoCuotaMultiple()
                                          "
                                       >
                                          Pagar cuotas seleccionadas
                                       </v-btn>
                                    </v-flex>
                                 </v-layout>
                                 <v-layout wrap>
                                    <!-- DATATABLE DETALLES DE CUOTAS PENDIENTES -->
                                    <v-flex xs12 sm12 style="margin-top: -20px">
                                       <v-data-table
                                          v-model="cuotasSeleccionadas"
                                          :headers="headersCuotas"
                                          :loading="loadingCuotas"
                                          loading-text="Listando cuotas... Por favor espere."
                                          :items="detallesPendientes"
                                          hide-default-footer
                                          disable-sort
                                          :items-per-page="-1"
                                          :footer-props="{
                                             itemsPerPageOptions: [-1],
                                             itemsPerPageText:
                                                'Filas por página:'
                                          }"
                                          item-key="npe"
                                          hidden-actions
                                          show-select
                                       >
                                          <template #header.data-table-select>
                                          </template>
                                          <template
                                             #item.data-table-select="{
                                                item,
                                                isSelected,
                                                select
                                             }"
                                          >
                                             <v-simple-checkbox
                                                v-if="!item.facturado"
                                                :value="isSelected"
                                                :disabled="item.facturado"
                                                @input="
                                                   select(
                                                      checkCondition(
                                                         $event,
                                                         item,
                                                         isSelected
                                                      )
                                                   )
                                                "
                                             ></v-simple-checkbox>
                                             <v-tooltip
                                                v-else
                                                top
                                                max-width="300"
                                                color="primary"
                                             >
                                                <template #activator="{ on }">
                                                   <v-icon
                                                      color="primary"
                                                      v-on="on"
                                                      >fa-check</v-icon
                                                   >
                                                </template>
                                                <span style="font-size: 15px">
                                                   Cuota cancelada
                                                </span>
                                             </v-tooltip>
                                          </template>

                                          <template #item.npe="{ item }">
                                             <div
                                                v-if="
                                                   $vuetify.breakpoint.smAndUp
                                                "
                                             >
                                                {{
                                                   disabledCuota(item, true)
                                                      ? '************************************'
                                                      : item.npe
                                                }}
                                             </div>
                                             <div
                                                v-if="
                                                   $vuetify.breakpoint.xsOnly
                                                "
                                                style="width: 150px"
                                             >
                                                <v-text-field
                                                   color="primary"
                                                   readonly
                                                   :value="
                                                      disabledCuota(item, true)
                                                         ? '************************************'
                                                         : item.npe
                                                   "
                                                >
                                                   <v-icon
                                                      v-if="
                                                         $vuetify.breakpoint
                                                            .xsOnly
                                                      "
                                                      slot="prepend"
                                                      color="primary"
                                                      :disabled="
                                                         disabledCuota(
                                                            item,
                                                            true
                                                         )
                                                      "
                                                      @click="
                                                         copyToClip(
                                                            disabledCuota(
                                                               item,
                                                               true
                                                            )
                                                               ? '************************************'
                                                               : item.npe
                                                         )
                                                      "
                                                      >far fa-copy</v-icon
                                                   >
                                                </v-text-field>
                                             </div>
                                          </template>
                                          <template #item.opciones="{ item }">
                                             <v-btn
                                                text
                                                small
                                                fab
                                                color="primary"
                                                :disabled="
                                                   disabledCuota(item) ||
                                                   item.facturado
                                                "
                                                @click="
                                                   disabledCuota(item) ||
                                                   item.facturado
                                                      ? false
                                                      : showPagoCuota(item)
                                                "
                                             >
                                                <v-icon>
                                                   far fa-credit-card
                                                </v-icon>
                                             </v-btn>
                                             <v-tooltip
                                                top
                                                max-width="300"
                                                color="primary"
                                                :disabled="!item.facturado"
                                             >
                                                <template
                                                   #activator="{ on, attrs }"
                                                >
                                                   <v-btn
                                                      text
                                                      small
                                                      fab
                                                      v-bind="attrs"
                                                      :color="
                                                         item.facturado
                                                            ? 'grey darken-1'
                                                            : 'orange'
                                                      "
                                                      :disabled="
                                                         disabledCuota(
                                                            item,
                                                            true
                                                         )
                                                      "
                                                      v-on="on"
                                                      @click="
                                                         disabledCuota(
                                                            item,
                                                            true
                                                         ) || item.facturado
                                                            ? listarPagos()
                                                            : imprimirCuota(
                                                                 item
                                                              )
                                                      "
                                                   >
                                                      <v-icon
                                                         >fas fa-print</v-icon
                                                      >
                                                   </v-btn>
                                                </template>
                                                <span>
                                                   Para volver imprimir un
                                                   comprobante dirijase a la
                                                   pantalla de historial de
                                                   pagos.
                                                </span>
                                             </v-tooltip>
                                          </template>
                                          <template #item.monto="{ item }">
                                             <div
                                                :class="
                                                   item.tipoBeca != null
                                                      ? 'colorPrimary'
                                                      : ''
                                                "
                                             >
                                                $
                                                {{
                                                   item.tipoBeca == null
                                                      ? numFormatIntl(
                                                           Number(item.monto) +
                                                              Number(
                                                                 item.recargo
                                                              )
                                                        )
                                                      : numFormatIntl(
                                                           Number(
                                                              item.montoConDescuento
                                                           ) +
                                                              Number(
                                                                 item.recargo
                                                              )
                                                        )
                                                }}
                                             </div>
                                          </template>

                                          <template #item.estado="{ item }">
                                             <v-chip
                                                :color="getColorEstado(item)"
                                                dark
                                             >
                                                <span>{{
                                                   item.facturado
                                                      ? 'Cancelado'
                                                      : getStatus(item)
                                                }}</span>
                                             </v-chip>
                                          </template>
                                          <template #no-data>
                                             <h3>
                                                No tienes cuotas pendientes
                                             </h3>
                                          </template>
                                       </v-data-table>
                                    </v-flex>
                                 </v-layout>
                              </v-card-text>
                           </v-card>
                        </v-flex>
                     </v-layout>
                     <v-btn class="mt-4" color="primary" @click="stepNum = 1">
                        Regresar
                     </v-btn>
                  </v-container>
               </v-stepper-content>

               <!-- PAGO DE ARANCELES -->
               <v-stepper-content
                  step="3"
                  :style="
                     $vuetify.breakpoint.xsOnly
                        ? 'margin: 0px 0px; padding: 0px 0px;'
                        : ''
                  "
               >
                  <v-container fluid grid-list-lg class="white pt-0 mt-1">
                     <v-layout wrap>
                        <v-flex xs12>
                           <v-toolbar flat color="white">
                              <v-toolbar-title class="text-h5 font-weight-bold"
                                 >Pagos de aranceles</v-toolbar-title
                              >
                              <v-spacer></v-spacer>
                           </v-toolbar>
                        </v-flex>
                        <v-flex v-if="pagosDeshabilitados" xs12 ml-4>
                           <p class="colorPrimary font-weight-bold text-h6">
                              Sistema en mantenimiento, se han desactivado las
                              funciones de pago en línea.
                              <v-icon
                                 color="primary"
                                 style="margin-top: -5px"
                                 right
                                 size="30"
                              >
                                 fas fa-info-circle
                              </v-icon>
                           </p>
                        </v-flex>
                        <v-flex xs12>
                           <!-- INFORMACION CLIENTE -->
                           <v-layout wrap class="pl-4 pr-4">
                              <v-flex xs12 sm2 class="smallForm">
                                 <v-text-field
                                    v-model="publicUser.carnet"
                                    color="primary"
                                    label="Carnet"
                                    readonly
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm2 class="smallForm">
                                 <v-text-field
                                    v-model="publicUser.dui"
                                    color="primary"
                                    :label="
                                       publicUser.extranjero
                                          ? 'N° de identificación'
                                          : 'DUI'
                                    "
                                    readonly
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm5 class="smallForm">
                                 <v-text-field
                                    v-model="nombreCompleto"
                                    label="Cliente"
                                    color="primary"
                                    readonly
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm3 class="smallForm">
                                 <v-text-field
                                    v-model="publicUser.email"
                                    color="primary"
                                    label="Email"
                                    readonly
                                 ></v-text-field>
                              </v-flex>
                           </v-layout>
                        </v-flex>
                        <v-flex xs12>
                           <v-form
                              ref="formPagoProgramaArancel"
                              v-model="validPagoProgramaArancel"
                              lazy-validation
                           >
                              <v-card class="borderDetallesPrimary">
                                 <v-card-text class="text--primary">
                                    <v-layout wrap>
                                       <v-flex xs12 sm4>
                                          <span class="text-h6"
                                             >Aranceles disponibles</span
                                          >
                                       </v-flex>
                                       <v-spacer></v-spacer>
                                       <v-flex xs12 sm5>
                                          <v-autocomplete
                                             v-model="selectArancel"
                                             :loading="loadingAranceles"
                                             :items="cmbArancel"
                                             color="primary"
                                             item-text="nombre"
                                             item-value="codigo"
                                             label="Arancel"
                                             no-data-text="Sin resultados"
                                             placeholder="Seleccionar..."
                                          >
                                             <template #item="{ item }">
                                                <v-list-item-content>
                                                   <v-list-item-title
                                                      v-text="item.nombre"
                                                   ></v-list-item-title>
                                                   <v-list-item-subtitle
                                                      v-text="
                                                         'Monto ' + item.precio
                                                      "
                                                   ></v-list-item-subtitle>
                                                </v-list-item-content>
                                             </template>
                                          </v-autocomplete>
                                       </v-flex>
                                       <v-flex
                                          v-if="
                                             selectArancel == '03-021' ||
                                             selectArancel == '03-120'
                                          "
                                          xs3
                                       >
                                          <v-autocomplete
                                             v-model="selectProgramaArancel"
                                             :items="cmbProgramas"
                                             :rules="rulesPrograma"
                                             color="primary"
                                             item-text="nombre"
                                             item-value="programaId"
                                             label="Programa"
                                             no-data-text="Sin resultados"
                                             placeholder="Seleccionar..."
                                          >
                                          </v-autocomplete>
                                       </v-flex>
                                    </v-layout>
                                    <v-layout wrap>
                                       <!-- DATATABLE DETALLES DE ARANCELES -->
                                       <v-flex
                                          xs12
                                          sm12
                                          style="margin-top: -20px"
                                       >
                                          <v-data-table
                                             hide-default-footer
                                             :headers="headersAraFiltered"
                                             :items="detallesAranceles"
                                             :items-per-page="-1"
                                             :footer-props="{
                                                itemsPerPageOptions: [-1],
                                                itemsPerPageText:
                                                   'Filas por página:'
                                             }"
                                             disable-sort
                                             hidden-actions
                                          >
                                             <template
                                                #item.opciones="{ item }"
                                             >
                                                <v-btn
                                                   text
                                                   small
                                                   fab
                                                   color="primary"
                                                   :disabled="
                                                      disabledArancel(item) ||
                                                      !validPagoProgramaArancel
                                                   "
                                                   @click="
                                                      disabledArancel(item) ||
                                                      item.facturado
                                                         ? false
                                                         : showPagoArancel(item)
                                                   "
                                                >
                                                   <v-icon
                                                      >far
                                                      fa-credit-card</v-icon
                                                   >
                                                </v-btn>
                                             </template>
                                             <template #no-data>
                                                <h3>Selecciona un arancel</h3>
                                             </template>
                                             <template #item.precio="{ item }">
                                                $
                                                {{
                                                   numFormatIntl(
                                                      Number(item.precio)
                                                   )
                                                }}
                                             </template>
                                          </v-data-table>
                                       </v-flex>
                                    </v-layout>
                                 </v-card-text>
                              </v-card>
                           </v-form>
                        </v-flex>
                     </v-layout>
                     <v-btn class="mt-4" color="primary" @click="stepNum = 1">
                        Regresar
                     </v-btn>
                  </v-container>
               </v-stepper-content>

               <!-- PAGO DE IDIOMAS -->
               <v-stepper-content
                  step="4"
                  :style="
                     $vuetify.breakpoint.xsOnly
                        ? 'margin: 0px 0px; padding: 0px 0px;'
                        : ''
                  "
               >
                  <v-container fluid grid-list-lg class="white pt-0 mt-1">
                     <v-layout wrap>
                        <v-flex xs12>
                           <v-toolbar flat color="white">
                              <v-toolbar-title class="text-h5 font-weight-bold"
                                 >Pagos de idiomas</v-toolbar-title
                              >
                              <v-spacer></v-spacer>
                           </v-toolbar>
                        </v-flex>
                        <v-flex v-if="pagosIdiomasDeshabilitados" xs12>
                           <v-card class="borderDetallesPrimary">
                              <v-card-text
                                 class="text--primary font-weight-medium"
                                 :style="
                                    $vuetify.breakpoint.smAndUp
                                       ? 'font-size: 18px;'
                                       : 'font-size: 15px;'
                                 "
                              >
                                 <v-layout wrap fill-height align-center>
                                    <v-flex xs12 sm1 text-center>
                                       <v-icon size="35" color="primary"
                                          >fa-info-circle</v-icon
                                       >
                                    </v-flex>
                                    <v-flex xs12 sm11 text-justify>
                                       <p
                                          class="font-weight-bold"
                                          style="margin-bottom: 10px"
                                       >
                                          Estimado alumno/a:
                                       </p>
                                       <p
                                          class="font-weight-medium"
                                          style="margin-bottom: 10px"
                                       >
                                          El periodo de inscripción para los
                                          módulos de idiomas ha finalizado.
                                       </p>
                                    </v-flex>
                                 </v-layout>
                              </v-card-text>
                           </v-card>
                        </v-flex>
                        <v-flex v-else xs12>
                           <v-layout wrap>
                              <v-flex xs12>
                                 <!-- INFORMACION CLIENTE -->
                                 <v-layout wrap class="pl-4 pr-4">
                                    <v-flex xs12 sm2 class="smallForm">
                                       <v-text-field
                                          v-model="publicUser.carnet"
                                          color="primary"
                                          label="Carnet"
                                          readonly
                                       ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm2 class="smallForm">
                                       <v-text-field
                                          v-model="publicUser.dui"
                                          color="primary"
                                          :label="
                                             publicUser.extranjero
                                                ? 'N° de identificación'
                                                : 'DUI'
                                          "
                                          readonly
                                       ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm5 class="smallForm">
                                       <v-text-field
                                          v-model="nombreCompleto"
                                          label="Cliente"
                                          color="primary"
                                          readonly
                                       ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm3 class="smallForm">
                                       <v-text-field
                                          v-model="publicUser.email"
                                          color="primary"
                                          label="Email"
                                          readonly
                                       ></v-text-field>
                                    </v-flex>
                                 </v-layout>
                              </v-flex>
                              <v-flex xs12>
                                 <v-card class="borderDetallesSecondary">
                                    <v-card-text class="text--primary">
                                       <v-layout wrap fill-height align-center>
                                          <v-flex xs12>
                                             <span class="text-h6">
                                                Último pago de idiomas
                                                registrado en Colecturía Digital
                                             </span>
                                          </v-flex>

                                          <v-flex xs12>
                                             <v-data-table
                                                :headers="headersPagosIdiomas"
                                                :items="ultimoPagoIdiomas"
                                                disable-sort
                                                hide-default-footer
                                                :items-per-page="10"
                                                hidden-actions
                                             >
                                                <template #item.historial="{}">
                                                   <v-btn
                                                      dark
                                                      color="orange darken-3"
                                                      @click="listarPagos"
                                                   >
                                                      Ver historial
                                                   </v-btn>
                                                </template>

                                                <template
                                                   #item.monto="{ item }"
                                                >
                                                   $
                                                   {{
                                                      numFormatIntl(item.monto)
                                                   }}
                                                </template>

                                                <template #no-data>
                                                   <h3>
                                                      No tienes pagos de idiomas
                                                      registrados
                                                   </h3>
                                                </template>
                                             </v-data-table>
                                          </v-flex>
                                       </v-layout>
                                    </v-card-text>
                                 </v-card>
                              </v-flex>
                              <v-flex xs12>
                                 <v-card class="borderDetallesPrimary">
                                    <v-card-text class="text--primary">
                                       <v-layout
                                          v-if="cmbModuloIdiomas.length > 0"
                                          wrap
                                       >
                                          <v-flex xs12>
                                             <span class="text-h6">
                                                Módulo de idiomas a pagar
                                             </span>
                                          </v-flex>
                                       </v-layout>
                                       <v-layout wrap>
                                          <v-flex
                                             xs12
                                             sm12
                                             style="margin-top: -20px"
                                          >
                                             <v-flex
                                                v-if="
                                                   cmbModuloIdiomas.length > 0
                                                "
                                             >
                                                <v-data-table
                                                   :headers="headersIdioma"
                                                   :items="cmbModuloIdiomas"
                                                   disable-sort
                                                   :items-per-page="10"
                                                   :footer-props="{
                                                      itemsPerPageOptions: [
                                                         10, 20, 30
                                                      ],
                                                      itemsPerPageText:
                                                         'Filas por página:'
                                                   }"
                                                   hidden-actions
                                                >
                                                   <template
                                                      #item.nivel="{ item }"
                                                   >
                                                      {{
                                                         intToRoman(
                                                            item.ultimoNivelAprobado
                                                         )
                                                      }}
                                                   </template>
                                                   <template
                                                      #item.monto="{ item }"
                                                   >
                                                      $
                                                      {{
                                                         numFormatIntl(
                                                            item.monto
                                                         )
                                                      }}
                                                   </template>
                                                   <template
                                                      #item.idioma="{ item }"
                                                   >
                                                      {{
                                                         item.idioma == 'EN'
                                                            ? 'Inglés'
                                                            : item.idioma ==
                                                              'FR'
                                                            ? 'Francés'
                                                            : item.idioma
                                                      }}
                                                   </template>
                                                   <template
                                                      #item.opciones="{ item }"
                                                   >
                                                      <v-btn
                                                         text
                                                         small
                                                         fab
                                                         color="primary"
                                                         @click="
                                                            showPagoIdioma(item)
                                                         "
                                                      >
                                                         <v-icon>
                                                            far fa-credit-card
                                                         </v-icon>
                                                      </v-btn>
                                                   </template>
                                                   <template #no-data>
                                                      <h3>
                                                         No se encontraron
                                                         registros
                                                      </h3>
                                                   </template>
                                                </v-data-table>
                                             </v-flex>
                                             <v-flex v-else class="mt-4">
                                                <span
                                                   class="text-subtitle-1 font-weight-medium"
                                                >
                                                   No tienes pagos pendientes
                                                   asociados a tu número de
                                                   carnet. Si crees que ha
                                                   habido algún error ó no te
                                                   aparece el pago de tu módulo
                                                   de idiomas, escríbenos al
                                                   correo de
                                                   <a>{{ correoDev }}</a>

                                                   <br /><br />

                                                   Si aún no cursas ningún
                                                   módulo de idiomas y deseas
                                                   registrarte
                                                   <a>por primera vez</a>
                                                   en uno, puedes hacerlo
                                                   completando este
                                                   <v-chip
                                                      color="primary"
                                                      label
                                                      link
                                                      @click="
                                                         dialogRegistroIdiomas = true
                                                      "
                                                   >
                                                      Formulario de inscripción </v-chip
                                                   >. Después de registrarte
                                                   esta página se actualizará y
                                                   le aparecerá disponible el
                                                   pago para el
                                                   <a>nivel 1</a>
                                                   del módulo que haya
                                                   seleccionado.
                                                </span>
                                             </v-flex>
                                          </v-flex>
                                       </v-layout>
                                    </v-card-text>
                                 </v-card>
                              </v-flex>
                           </v-layout>
                        </v-flex>
                     </v-layout>
                     <v-btn class="mt-4" color="primary" @click="stepNum = 1">
                        Regresar
                     </v-btn>
                  </v-container>
               </v-stepper-content>

               <!-- HISTORIAL DE PAGOS -->
               <v-stepper-content
                  step="5"
                  :style="
                     $vuetify.breakpoint.xsOnly
                        ? 'margin: 0px 0px; padding: 0px 0px;'
                        : ''
                  "
               >
                  <v-container fluid grid-list-lg class="white pt-0 mt-1">
                     <v-layout wrap>
                        <v-flex xs12>
                           <v-toolbar flat color="white">
                              <v-toolbar-title class="text-h5 font-weight-bold">
                                 Historial de pagos
                              </v-toolbar-title>
                              <v-spacer></v-spacer>
                           </v-toolbar>
                        </v-flex>
                        <v-flex xs12>
                           <!-- INFORMACION CLIENTE -->
                           <v-layout wrap class="pl-4 pr-4">
                              <v-flex xs12 sm2 class="smallForm">
                                 <v-text-field
                                    v-model="publicUser.carnet"
                                    color="primary"
                                    label="Carnet"
                                    readonly
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm2 class="smallForm">
                                 <v-text-field
                                    v-model="publicUser.dui"
                                    color="primary"
                                    :label="
                                       publicUser.extranjero
                                          ? 'N° de identificación'
                                          : 'DUI'
                                    "
                                    readonly
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm5 class="smallForm">
                                 <v-text-field
                                    v-model="nombreCompleto"
                                    label="Cliente"
                                    color="primary"
                                    readonly
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm3 class="smallForm">
                                 <v-text-field
                                    v-model="publicUser.email"
                                    color="primary"
                                    label="Email"
                                    readonly
                                 ></v-text-field>
                              </v-flex>
                           </v-layout>
                        </v-flex>
                        <v-flex xs12>
                           <v-card class="borderDetallesPrimary">
                              <v-card-text class="text--primary">
                                 <v-layout wrap>
                                    <v-flex xs12 sm6>
                                       <div
                                          class="text-h6"
                                          style="padding-top: 20px"
                                       >
                                          Pagos registrados por medio de
                                          Colecturía Usuarios Externos
                                       </div>
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                       <v-text-field
                                          v-model="searchPagos"
                                          color="primary"
                                          append-icon="fa-search"
                                          label="Busqueda"
                                       ></v-text-field>
                                    </v-flex>
                                 </v-layout>
                                 <v-layout wrap>
                                    <!-- DATATABLE DETALLES DE ARANCELES -->
                                    <v-flex xs12>
                                       <v-data-table
                                          :headers="headersPagos"
                                          :items="pagosRegistrados"
                                          :loading="loadingPagos"
                                          :search="searchPagos"
                                          loading-text="Listando pagos... Por favor espere."
                                          disable-sort
                                          :items-per-page="10"
                                          :footer-props="{
                                             itemsPerPageOptions: [10, 20, 30],
                                             itemsPerPageText:
                                                'Filas por página:'
                                          }"
                                          hidden-actions
                                       >
                                          <template #item.total="{ item }">
                                             $
                                             {{ item.total }}
                                          </template>
                                          <template #item.concepto="{ item }">
                                             {{ generarConcepto(item) }}
                                          </template>
                                          <template
                                             #item.comprobante="{ item }"
                                          >
                                             <v-btn
                                                dark
                                                color="orange darken-3"
                                                @click="
                                                   reImprimirComprobantePago(
                                                      item
                                                   )
                                                "
                                             >
                                                Comp. Pago
                                             </v-btn>
                                          </template>
                                          <template #no-data>
                                             <h3>
                                                No tienes pagos registrados
                                             </h3>
                                          </template>
                                       </v-data-table>
                                    </v-flex>
                                 </v-layout>
                              </v-card-text>
                           </v-card>
                        </v-flex>
                     </v-layout>
                     <v-btn class="mt-4" color="primary" @click="stepNum = 1">
                        Regresar
                     </v-btn>
                  </v-container>
               </v-stepper-content>
            </v-stepper-items>
         </v-stepper>

         <!-- SI NO ESTA VERIFICADO -->
         <v-container v-if="!publicUserLoggedIn" fluid>
            <v-layout justify-center wrap>
               <v-flex
                  xs12
                  class="text-center mt-8"
                  :style="
                     $vuetify.breakpoint.smAndUp
                        ? 'padding-left: 10%; padding-right: 10%'
                        : ''
                  "
               >
               </v-flex>
            </v-layout>
         </v-container>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import formatters from '../mixins/formattersLib'
import { Base64 } from 'js-base64'
import { mask } from 'vue-the-mask'
import { authComputed } from '../helpers'
import jQuery from 'jquery'
import InputNumber from '../components/custom/InputNumber'
import ClienteForm from '../components/ClienteForm'
import RegistroIdiomas from '../components/RegistroIdiomas'
const getReporteLib = () => import('../mixins/reporteLib')

// Definiendo variables
const $ = jQuery
window.$ = $
window.jQuery = $

export default {
   name: 'ColecturiaExterna',
   components: {
      'v-input-number': InputNumber,
      ClienteForm: ClienteForm,
      RegistroIdiomas: RegistroIdiomas
   },
   directives: {
      mask
   },
   mixins: [formatters],

   data: (vm) => ({
      // Imagenes
      logo64: null,
      cda64: null,
      selloCancelado: null,
      selloCanceladoNoDate: null,
      emptyImage: null,
      correoDev: process.env.VUE_APP_CORREO_DEV,
      selectCarreraUniversitaria: '',

      // Variables
      sede: process.env.VUE_APP_SEDE,
      version: process.env.VUE_APP_VERSION,
      mapState: window.mapState,
      pagosDeshabilitados: false,
      cupoAgotado: false,
      pagosIdiomasDeshabilitados: false,
      mostrarMensaje: false,
      mensajeImportante: '',
      cicloActual: '',
      loadingAranceles: false,
      loadingCuotas: false,
      dialogEsperaPago: false,
      nuevoCarnet: '',
      dialogCarnetGenerado: false,
      dialogEsperaMessage: 'Esperando respuesta de ventana de pago.',
      dialogEsperaSubMessage: 'No cierre esta pestaña o el navegador.',
      dialogEsperaColor: 'primary',
      dialogClienteInfo: false,
      dialogRegistroIdiomas: false,
      dialogReiniciar: false,
      modeClienteInfo: 'post',
      itemCantidad: 1,
      itemPrecio: 0,
      itemCodigo: '',
      arancelCantidad: false,
      arancelDescripcion: '',
      arancelReqFacultad: false,
      cuotaTipoBeca: null,
      cuotaDescuento: 0,
      totalSinDescuento: 0,
      totalConDescuento: 0,
      editedIndex: -1,
      popupRes: [],
      insertPagoRes: [],
      pagosRegistrados: [],
      ultimoPagoIdiomas: [],
      loadingPagosIdiomas: false,
      loadingPagos: false,
      searchPagos: '',
      headersIdioma: [
         {
            text: 'Idioma',
            value: 'idioma',
            class: 'titulo'
         },
         {
            text: 'Nivel',
            value: 'nivel',
            class: 'titulo'
         },
         {
            text: 'Programa',
            value: 'programaDescripcion',
            class: 'titulo'
         },
         {
            text: 'Opciones',
            value: 'opciones',
            sortable: false,
            width: '125',
            align: 'right',
            class: 'titulo'
         }
      ],
      headersPagos: [
         {
            text: 'Fecha de pago',
            value: 'fecha',
            class: 'titulo'
         },
         {
            text: 'Tipo de pago',
            value: 'tipoPago',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Concepto',
            width: '275',
            value: 'concepto',
            class: 'titulo'
         },
         {
            text: 'Referencia',
            value: 'referencia',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Autorizacion',
            value: 'autorizacion',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Monto',
            value: 'total',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Comp. Pago',
            value: 'comprobante',
            sortable: false,
            align: 'right',
            class: 'titulo'
         }
      ],
      headersPagosIdiomas: [
         {
            text: 'Fecha de pago',
            value: 'fecha',
            class: 'titulo'
         },
         {
            text: 'Concepto',
            value: 'concepto',
            class: 'titulo'
         },
         {
            text: 'Monto',
            value: 'monto',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Opciones',
            value: 'historial',
            sortable: false,
            align: 'right',
            class: 'titulo'
         }
      ],
      headersAranceles: [
         {
            text: 'Nombre',
            value: 'descripcion',
            class: 'titulo',
            sortable: false
         },
         {
            text: 'Monto',
            value: 'precioUnitario',
            class: 'titulo',
            align: 'right',
            sortable: false
         }
      ],
      headersAraComple: [
         {
            text: 'Nombre',
            value: 'descripcion',
            class: 'titulo',
            sortable: false
         },
         {
            text: 'Codigo',
            value: 'codigoMateria',
            class: 'titulo',
            align: 'right',
            sortable: false
         },
         {
            text: 'Materia',
            value: 'materia',
            class: 'titulo',
            align: 'right',
            sortable: false
         },
         {
            text: 'Sección',
            value: 'seccion',
            class: 'titulo',
            align: 'right',
            sortable: false
         },
         {
            text: 'Promedio',
            value: 'promedioCiclo',
            class: 'titulo',
            align: 'right',
            sortable: false
         },
         {
            text: 'Asistencia',
            value: 'asistenciaCiclo',
            class: 'titulo',
            align: 'right',
            sortable: false
         },
         {
            text: 'Fecha de pago',
            value: 'fechaDePago',
            class: 'titulo',
            align: 'right',
            sortable: false
         },
         {
            text: 'Monto',
            value: 'precio',
            class: 'titulo',
            align: 'right',
            sortable: false
         },
         {
            text: 'Opciones',
            value: 'opciones',
            sortable: false,
            width: '125',
            align: 'right',
            class: 'titulo'
         }
      ],
      headersAraDife: [
         {
            text: 'Nombre',
            value: 'descripcion',
            class: 'titulo',
            sortable: false
         },
         {
            text: 'Codigo',
            value: 'codigoMateria',
            class: 'titulo',
            align: 'right',
            sortable: false
         },
         {
            text: 'Materia',
            value: 'materia',
            class: 'titulo',
            align: 'right',
            sortable: false
         },
         {
            text: 'Sección',
            value: 'seccion',
            class: 'titulo',
            align: 'right',
            sortable: false
         },
         {
            text: 'Año',
            value: 'annio',
            class: 'titulo',
            align: 'right',
            sortable: false
         },
         {
            text: 'Ciclo',
            value: 'ciclo',
            class: 'titulo',
            align: 'right',
            sortable: false
         },
         {
            text: 'Periodo',
            value: 'periodo',
            class: 'titulo',
            align: 'right',
            sortable: false
         },
         {
            text: 'Nota',
            value: 'parcialNota',
            class: 'titulo',
            align: 'right',
            sortable: false
         },
         {
            text: 'Asistencia',
            value: 'asistencia',
            class: 'titulo',
            align: 'right',
            sortable: false
         },
         {
            text: 'Monto',
            value: 'precio',
            class: 'titulo',
            align: 'right',
            sortable: false
         },
         {
            text: 'Opciones',
            value: 'opciones',
            sortable: false,
            width: '125',
            align: 'right',
            class: 'titulo'
         }
      ],
      headersAra: [
         {
            text: 'Nombre',
            value: 'nombre',
            class: 'titulo',
            sortable: false
         },
         {
            text: 'Monto',
            value: 'precio',
            class: 'titulo',
            align: 'right',
            sortable: false
         },
         {
            text: 'Opciones',
            value: 'opciones',
            sortable: false,
            width: '125',
            align: 'right',
            class: 'titulo'
         }
      ],
      headersCuotas: [
         { text: 'Seleccionar', value: 'data-table-select', sortable: false },
         {
            text: 'Ciclo',
            value: 'periodoLectivo',
            class: 'titulo',
            align: 'center',
            sortable: false
         },
         { text: 'NPE', value: 'npe', class: 'titulo', sortable: false },
         {
            text: 'N° Cuota',
            value: 'cuota',
            class: 'titulo',
            align: 'center',
            sortable: false
         },
         {
            text: 'Fecha de vencimiento',
            value: 'fechaVencimiento',
            class: 'titulo',
            align: 'center',
            sortable: false
         },
         {
            text: 'Fecha de pago',
            value: 'fechaDePago',
            class: 'titulo',
            align: 'center',
            sortable: false
         },
         {
            text: 'Monto',
            value: 'monto',
            class: 'titulo',
            align: 'right',
            sortable: false
         },
         {
            text: 'Estado',
            value: 'estado',
            class: 'titulo',
            align: 'center',
            sortable: false
         },
         {
            text: 'Opciones',
            value: 'opciones',
            sortable: false,
            width: '125',
            align: 'right',
            class: 'titulo'
         }
      ],
      detallesAranceles: [],
      headersAraFiltered: [],

      // Variables para validacion de query
      carnet: '',
      accesscode: '',
      poseeMora: true,
      clienteMessage: '',

      // Variables de formularios
      dialogDemoTalonario: false,
      dialogPagoCuota: false,
      dialogPagoCuotaMultiple: false,
      dialogPagoArancel: false,
      dialogReporteLoading: false,
      dialogPagoIdioma: false,
      btnDetalleColor: 'primary white--text',
      talonario: [],
      aranceles: [],
      periodoLectivo: [],
      recargo: 0,
      recargoIdiomas: 0,
      detalleIcon: 'fa-plus',
      dialogEliminarTalonario: false,
      dialogEliminarDetalle: false,
      fecha: vm.toISOLocal(new Date()).substr(0, 10),
      fechaAnulacion: vm.toISOLocal(new Date()).substr(0, 10),
      radiosBusqueda: 'r-numeroTalonario',
      radiosOption: 'r-individual',
      search: '',
      show: false,
      clienteId: null,
      stepNum: 1,

      // Propiedades talonarios
      cmbEstadoTalonario: [],

      // Talonario
      selectSede: '',
      cmbSede: [],
      unidadEducativa: '',
      numeroTalonario: '',
      cicloDescripcion: '',
      gradoAcademico: 0,
      selectUnidadAcademica: null,
      cmbUnidadesAcademicas: [],
      selectDecanato: '',
      cmbDecanato: [],
      selectConfPago: '',
      cmbConfPago: [],
      cmbArancel: [],
      selectArancel: '',
      alumnoMadre: false,
      cmbCarreraArancel: [],
      carreraNombre: '',

      // Variables detalles de talonario
      detalles: [],
      detallesPendientes: [],
      cuotasSeleccionadas: [],
      cuotasPagoMultiple: [],
      detallePagoArancel: [],
      totalMora: '',
      npe: '',
      npes: [],
      detallesComprobantes: [],
      cuotaFacturada: false,
      fechaDePago: '',
      numeroCuota: '',
      selectEstadoDetalle: '',
      cmbEstadoDetalle: '',
      filtroCuotas: '',

      // Variables para serfinsa
      totalPagar: '',
      idTransaccion: '',
      tokenSerfinsa: '',
      tokenContabilidad: process.env.VUE_APP_TOKEN_CONTA,
      tokenIdiomas: process.env.VUE_APP_TOKEN_LIBRERIA,
      idPagos: [],

      // Variables para pago aranceles
      arancelCodigo: '',
      seccion: '',
      periodoDeCiclo: '',
      decanatoCodigo: '',
      materiaCodigo: '',
      materiaNombre: '',

      // Variables de modulo de idiomas
      disablePagoIdioma: false,
      nivelDeshabilitado: false,
      idiomaCodigoAlumno: '',
      idiomaCuota: '',
      idiomaNivel: '',
      idiomaMonto: '',
      idiomaProgramaId: '',
      idiomaProgramaDesc: '',
      idiomaProgramaCodigo: '',
      idiomaVencido: false,
      idiomaMesesDesdeUltimoModulo: 0,
      idiomaLimiteMesesInacividad: 0,
      idiomaNombre: '',
      idiomaCorreo:
         process.env.VUE_APP_SEDE == 'SA'
            ? 'idiomas@catolica.edu.sv'
            : 'english.cri@catolica.edu.sv',
      idiomaTelefono:
         process.env.VUE_APP_SEDE == 'SA' ? '2484-0614' : '2378-1520',
      cmbModuloIdiomas: [],
      cmbProgramas: [],
      nivelesProgramados: [],
      selectPrograma: '',
      selectProgramaArancel: '',
      validPagoProgramaArancel: true,
      validPagoIdioma: true,
      rulesPrograma: [(v) => !!v || 'Seleccione un programa'],

      // Variables adicionales para comprobante de pago
      autorizacion: '',
      referencia: '',
      resImprimir: [],

      // Otros
      read: 0,
      result: [],
      snackColor: 'primary',
      snackText: '',
      snackbar: false,
      valida: 0,
      verNuevo: 0,
      cicloNombre: '',
      ntalonario: '',
      nombrePago: '',
      carnetPago: '',
      numTarjeta: '',
      cvv: '',

      // Validacion de forms
      validPagoArancel: true,
      rulesFacultad: [(v) => !!v || 'Seleccione una carrera universitaria']
   }),
   computed: {
      ...authComputed,
      nombreCompleto() {
         return `${this.publicUser.nombres} ${this.publicUser.apellidos}`
      },
      debeComprobantes() {
         return this.detallesPendientes.some(
            (c) =>
               c.facturado &&
               c.tipoBeca != null &&
               c.seHaSubidoComprobante == null &&
               c.fechaDePago != 'PAGO BANCO'
         )
      },
      // Titulo de dialog
      formTitle() {
         return this.editedIndex === -1 ? 'Nueva Bodega' : 'Editar Bodega'
      },
      filteredCuotaArancel() {
         var res = []
         this.aranceles.forEach((arancel) => {
            // Imprimiendo aranceles (U)nicos solo en primera cuota
            if (
               arancel.tipoCobro == 'U' &&
               this.numeroCuota == 1 &&
               !arancel.extraordinaria &&
               !arancel.procesoGraduacion &&
               !this.talonario.procesoGraduacion
            ) {
               res.push(arancel)
            }

            // Impriendo aranceles (R)ecurrentes en todas las cuotas
            if (
               arancel.tipoCobro == 'R' &&
               !arancel.extraordinaria &&
               !arancel.procesoGraduacion &&
               !this.talonario.procesoGraduacion
            ) {
               res.push(arancel)
            }

            // Impriendo arancel de proceso gracuadion si el talonario esta en proceso de graduacion
            if (
               arancel.tipoCobro == 'R' &&
               !arancel.extraordinaria &&
               arancel.procesoGraduacion &&
               this.talonario.procesoGraduacion
            ) {
               res.push(arancel)
            }
         })

         // Si tiene recargo se anexa arancel de PAGO EXTEMPORANEO
         if (Number(this.recargo) > 0) {
            var pagoExtemporaneo = {}
            pagoExtemporaneo.descripcion = 'PAGO EXTEMPORANEO'
            pagoExtemporaneo.montoImpuesto = 0.0
            pagoExtemporaneo.precioUnitario = this.recargo
            //console.log(pagoExtemporaneo)
            res.push(pagoExtemporaneo)
         } else {
            var recargoExonerado = {}
            recargoExonerado.descripcion = 'RECARGO EXONERADO'
            recargoExonerado.montoImpuesto = 0.0
            recargoExonerado.precioUnitario = 0.0
            //console.log(recargoExonerado)
            res.push(recargoExonerado)
         }
         return res
      }
   },
   watch: {
      publicUser(val) {
         if (val) {
            this.validarAlumnoMadre()
         }
      },
      selectPrograma(val) {
         if (val != '' && val != null) {
            this.disablePagoIdioma = true
            var idiomaCodigo = this.cmbProgramas.find(
               (obj) => obj.programaId == val
            ).codigo
            this.nivelDeshabilitado =
               this.nivelesProgramados.find(
                  (obj) =>
                     obj.codigo == idiomaCodigo && obj.nivel == this.idiomaNivel
               ) != undefined
                  ? false
                  : true
            this.cupoAgotado =
               this.cmbProgramas.find((obj) => obj.programaId == val).cupos ==
                  0 && this.idiomaNivel == 1
                  ? true
                  : false
            this.disablePagoIdioma =
               this.nivelDeshabilitado || this.cupoAgotado ? true : false
            this.getRecargoIdiomas(val)
         }
      },
      publicUserLoggedIn(val) {
         if (val) {
            this.alumno =
               this.publicUser.nombres.toUpperCase() +
               ' ' +
               this.publicUser.apellidos.toUpperCase()
            this.$refs.ClienteForm.reset()
            setTimeout(() => {
               this.validarInfoCompleta()
            }, 300)
         }
      },
      itemCantidad(val) {
         this.totalPagar = this.toFixedRegex(this.itemPrecio * val, 2)
      },
      // Revisar si algun pago ya se encuentra registrado exitosamente en tabla de pagos interna
      detallesPendientes(val) {
         // Obtener ultimo ciclo del final de la tabla
         if (val.length > 0) {
            this.cicloActual = val[val.length - 1].periodoLectivo
         }
      },
      // Esperando respuesta de callback de Serfinsa
      'mapState.callBackPago'(value) {
         if (value) {
            this.popupRes = value
            this.dialogEsperaColor = 'snackInfo'
            this.dialogEsperaMessage = 'Generando comprobante de pago...'
            this.dialogEsperaSubMessage =
               'No cierre esta pestaña o el navegador.'
            this.showSnack(
               'Generando comprobante de pago, por favor espere...',
               'snackInfo'
            )
            // Al recibir una respuesta del popup generar comprobante
            if (this.stepNum == 2 && value.Codigo == '00') {
               // Descargar comprobante de pago cuotas
               this.imprimirComprobantePago(
                  this.insertPagoRes,
                  4500,
                  'save',
                  'cuota'
               ).then(() => {
                  // Refrescar lista de pagos
                  this.dialogEsperaPago = false
                  this.listarCuotasPendientes()
                  this.cuotasSeleccionadas = []
                  this.closePagoCuota()
                  this.closePagoCuotaMultiple()
                  this.showSnack(
                     'Tu pago de cuota/s se ha registrado correctamente!',
                     'snackSuccess'
                  )
               })
            } else if (this.stepNum == 3 && value.Codigo == '00') {
               // Descargar comprobante de pago de arancel
               this.imprimirComprobantePago(
                  this.insertPagoRes,
                  4500,
                  'save',
                  'arancel'
               ).then(() => {
                  // Refrescar lista de aranceles
                  this.dialogEsperaPago = false
                  this.irPagoAranceles()
                  this.closePagoArancel()
                  this.showSnack(
                     'Tu pago de arancel se ha registrado correctamente!',
                     'snackSuccess'
                  )
               })
            } else if (this.stepNum == 4 && value.Codigo == '00') {
               // Descargar comprobante de pago de idiomas
               this.idiomaNivel = this.insertPagoRes[0].nivel
               this.idiomaProgramaDesc = this.insertPagoRes[0].programa
               this.idiomaNombre = this.insertPagoRes[0].idioma
               this.imprimirComprobantePago(
                  this.insertPagoRes,
                  4500,
                  'save',
                  'arancel'
               ).then(() => {
                  // Refrescar lista de aranceles
                  this.dialogEsperaPago = false
                  this.closePagoIdioma()
                  this.irPagoIdiomas()
                  this.showSnack(
                     'Tu pago de idiomas se ha registrado correctamente!',
                     'snackSuccess'
                  )
               })
            } else {
               this.showSnack(
                  'Tu pago no se realizó correctamente.',
                  'snackError'
               )
            }
         }
      },
      // Mostrar aranceles en datatable
      selectArancel(val) {
         if (val != '') {
            this.loadingAranceles = true
            this.selectProgramaArancel = null
            this.listarAranceles()
         }
      }
   },
   created() {
      // Obteniendo imagenes base64 para impresiones
      this.toDataURL('/assets/logo_u_black-300.png').then((result) => {
         this.logo64 = result
      })
      this.toDataURL('/assets/CdA_black-300.png').then((result) => {
         this.cda64 = result
      })
      this.toDataURL('/assets/sello-cancelado-red-400.png').then((result) => {
         this.selloCancelado = result
      })
      this.toDataURL('/assets/sello-cancelado-nodate-400.png').then(
         (result) => {
            this.selloCanceladoNoDate = result
         }
      )
      this.toDataURL('/assets/empty-400.png').then((result) => {
         this.emptyImage = result
      })

      // Al cargar el componente se carga las detalles
      this.listarComboboxes()
      if (!this.publicUserLoggedIn) {
         this.dialogClienteInfo = true
      } else {
         this.alumno =
            this.publicUser.nombres.toUpperCase() +
            ' ' +
            this.publicUser.apellidos.toUpperCase()
         this.validarInfoCompleta()
      }
      this.obtenerEstadoSistema()
   },
   mounted() {
      // Agregando Serfinsa Pay
      // https://www.serfinsacheckout.com/Scripts/Serfinsa.Pay.js
      // https://test.serfinsacheckout.com:8080/Scripts/Serfinsa.Pay.js
      let serfinsaScript = document.createElement('script')
      serfinsaScript.setAttribute('src', process.env.VUE_APP_SERFINSA_URL)

      document.head.appendChild(serfinsaScript)
   },
   methods: {
      getRecargoIdiomas(programaIdiomasId) {
         axios
            .get('/api/pagosIdiomas/recargo?programaId=' + programaIdiomasId)
            .then((response) => {
               this.recargoIdiomas = response.data.recargo
               this.totalPagar =
                  parseFloat(this.idiomaMonto) + parseFloat(this.recargoIdiomas)
            })
            .catch((error) => {
               console.log('error:' + error.response)
            })
      },
      reiniciarNivel() {
         axios
            .put('api/ProgramaIdioma/ReiniciarNivel', {
               programa: this.idiomaProgramaCodigo,
               year: Number(this.toISOLocal(new Date()).substr(0, 4)),
               codigoIdiomas: this.idiomaCodigoAlumno,
               cuota: this.idiomaCuota
            })
            .then(() => {
               this.dialogReiniciar = false
               this.showSnack(
                  'Nivel reiniciado correctamente. Refrescando...',
                  'snackSuccess'
               )
               setTimeout(() => {
                  this.refreshPage()
               }, 4000)
            })
            .catch(() => {
               this.showSnack('Algo ha ido mal...', 'snackError')
            })
      },
      checkRangoAlto() {
         if (this.oidcUser) {
            return Number(this.oidcUser.nivelasesor) > 1 ||
               this.oidcUser.role == 'admin'
               ? true
               : false
         } else {
            return false
         }
      },
      closeCarnetGenerado() {
         this.dialogCarnetGenerado = false
         this.refreshPage()
      },
      showCarnetGenerado(carnet) {
         this.nuevoCarnet = carnet
         axios.get('api/cliente/' + this.nuevoCarnet).then((response) => {
            if (response.data) {
               // Retornar identidad a componente padre
               this.$store.dispatch('login', response.data)
               this.dialogCarnetGenerado = true
            }
         })
      },
      validatePagoIdiomas() {
         if (this.$refs.formPagoIdioma.validate()) {
            this.initPago()
         }
      },
      irPagoIdiomas() {
         var idiomaId = null
         this.loadingPagosIdiomas = true
         this.obtenerEstadoSistema()
         axios
            .get('api/pagosIdiomas/codigo?codigo=' + this.publicUser.carnet)
            .then((response) => {
               this.cmbModuloIdiomas = []
               this.cmbModuloIdiomas.push(response.data)
               switch (response.data.idioma) {
                  case 'EN':
                     idiomaId = 2
                     break
                  case 'FR':
                     idiomaId = 6
                     break
                  default:
                     idiomaId = null
                     break
               }
            })
            .catch(() => {
               this.cmbModuloIdiomas = []
               //console.log(error)
            })
            .finally(() => {
               axios
                  .get(
                     `api/Pago?Carnet=${this.publicUser.carnet}&ResponseCode=00&BotonDePagoId=2`
                  )
                  .then((response) => {
                     var pagosIdiomas = response.data
                     var last =
                        pagosIdiomas.length > 0
                           ? pagosIdiomas[pagosIdiomas.length - 1]
                           : null
                     this.ultimoPagoIdiomas = []
                     if (last != null) {
                        this.ultimoPagoIdiomas.push(last)
                     }
                  })
                  .finally(() => {
                     if (this.cmbModuloIdiomas.length > 0) {
                        axios
                           .get(
                              'api/ProgramaIdioma?activo=true&idiomaId=' +
                                 idiomaId
                           )
                           .then((response) => {
                              this.cmbProgramas = []
                              this.cmbProgramas = response.data
                           })
                           .finally(() => {
                              axios
                                 .get('api/pagosIdiomas/niveles-programados')
                                 .then((response) => {
                                    this.nivelesProgramados = response.data
                                 })
                                 .finally(() => {
                                    this.loadingPagosIdiomas = false
                                    this.stepNum = 4
                                 })
                           })
                     } else {
                        this.loadingPagosIdiomas = false
                        this.stepNum = 4
                     }
                  })
            })
      },
      refreshPage() {
         location.reload()
      },
      // Validar que el usuario logeado tenga su información completa
      validarInfoCompleta() {
         if (
            this.publicUser.dui == null ||
            this.publicUser.nit == null ||
            this.publicUser.direccion == null ||
            this.publicUser.telefono == null ||
            this.publicUser.email == null
         ) {
            this.modeClienteInfo = 'put'
            this.dialogClienteInfo = true
         } else {
            this.modeClienteInfo = 'post'
            this.dialogClienteInfo = false
         }
      },
      // Descargar un talonario completo para imprimir
      descargarTalonarioCompleto() {
         this.periodoLectivo = this.cicloActual
         this.getInfoTalonario().then(() => {
            this.generarReporteTalonario('save', true, null)
         })
      },
      // Genera reporte de cuota
      generarReporteTalonario(mode, portadas = true, filtroCuotas = null) {
         var filteredDetalles = []

         // Filtrando cuotas si es solicitado
         if (filtroCuotas) {
            var res = filtroCuotas.split(',').map((item) => {
               return item.trim()
            })
            filteredDetalles = this.talonario.contratoPlanPagos.filter((el) =>
               res.includes(el.cuota.toString())
            )
            filteredDetalles =
               filteredDetalles.length == 0
                  ? this.talonario.contratoPlanPagos
                  : filteredDetalles
         } else {
            filteredDetalles = this.talonario.contratoPlanPagos
         }

         const getDetalleAranceles = (cuota) => {
            var result = []
            this.pagoExtemporaneo = Number(cuota.monto) + 5
            var fS = this.aranceles.length < 5 ? 8 : 7
            this.aranceles.forEach((arancel) => {
               // Imprimiendo aranceles (U)nicos solo en primera cuota
               if (
                  arancel.tipoCobro == 'U' &&
                  cuota.cuota == 1 &&
                  !arancel.extraordinaria &&
                  !arancel.procesoGraduacion &&
                  !this.talonario.procesoGraduacion
               ) {
                  result.push([
                     {
                        text: arancel.articulo,
                        alignment: 'right',
                        fontSize: fS
                     },
                     {
                        text: arancel.descripcion.replace('_', ' '),
                        fontSize: fS
                     },
                     { text: '$', fontSize: fS },
                     {
                        text: this.toFixedRegex(
                           Number(arancel.precioUnitario) +
                              Number(arancel.montoImpuesto),
                           2
                        ),
                        fontSize: fS,
                        margin: [0, 0, 35, 0],
                        alignment: 'right'
                     }
                  ])
               }

               // Impriendo aranceles (R)ecurrentes en todas las cuotas
               if (
                  arancel.tipoCobro == 'R' &&
                  !arancel.extraordinaria &&
                  !arancel.procesoGraduacion &&
                  !this.talonario.procesoGraduacion
               ) {
                  result.push([
                     {
                        text: arancel.articulo,
                        alignment: 'right',
                        fontSize: fS
                     },
                     {
                        text: arancel.descripcion.replace('_', ' '),
                        fontSize: fS
                     },
                     { text: '$', fontSize: fS },
                     {
                        text: this.toFixedRegex(
                           Number(arancel.precioUnitario) +
                              Number(arancel.montoImpuesto),
                           2
                        ),
                        fontSize: fS,
                        margin: [0, 0, 35, 0],
                        alignment: 'right'
                     }
                  ])
               }

               // Impriendo arancel de proceso gracuadion si el talonario esta en proceso de graduacion
               if (
                  arancel.tipoCobro == 'R' &&
                  !arancel.extraordinaria &&
                  arancel.procesoGraduacion &&
                  this.talonario.procesoGraduacion
               ) {
                  result.push([
                     {
                        text: arancel.articulo,
                        alignment: 'right',
                        fontSize: fS
                     },
                     {
                        text: arancel.descripcion.replace('_', ' '),
                        fontSize: fS
                     },
                     { text: '$', fontSize: fS },
                     {
                        text: this.toFixedRegex(
                           Number(arancel.precioUnitario) +
                              Number(arancel.montoImpuesto),
                           2
                        ),
                        fontSize: fS,
                        margin: [0, 0, 35, 0],
                        alignment: 'right'
                     }
                  ])
               }
            })
            result.push([
               {
                  text: 'TOTAL A PAGAR',
                  fontSize: 10,
                  margin: [0, 0, 15, 0],
                  bold: true,
                  alignment: 'right',
                  colSpan: 2
               },
               {},
               {
                  text: '$',
                  fontSize: 10,
                  bold: true
               },
               {
                  text: cuota.monto,
                  fontSize: 10,
                  bold: true,
                  margin: [0, 0, 35, 0],
                  alignment: 'right'
               }
            ])
            return result
         }

         const getTalonarioBody = (showPortadas) => {
            var resultPage = []

            // ------------------------------------ PORTADA ----------------------------------------
            if (showPortadas) {
               resultPage.push([
                  {
                     table: {
                        widths: ['*', 120, '*'],
                        body: [
                           [
                              {
                                 text: 'UNIVERSIDAD CATÓLICA DE EL SALVADOR',
                                 font: 'TimesNewRoman',
                                 fontSize: 20,
                                 bold: true,
                                 alignment: 'center',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: 'NUESTRA MISIÓN',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              },
                              {
                                 image: this.logo64,
                                 alignment: 'center',
                                 rowSpan: 2,
                                 width: 90
                              },
                              {
                                 text: 'NUESTRA VISIÓN',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              }
                           ],
                           [
                              {
                                 text:
                                    'La formación de personas, inspirada en los principios cristianos y en los conocimientos' +
                                    ' técnicos y científicos, orientada a una constante búsqueda de la verdad y del uso del saber,' +
                                    ' para contribuir a la tutela y desarrollo de la dignidad humana y de la sociedad,' +
                                    ' mediante la investigación, docencia y la proyección social.',
                                 alignment: 'justify'
                              },
                              {},
                              {
                                 text:
                                    'Seremos una universidad con excelentes servicios de educación superior, líderes en la' +
                                    ' formación integral de la persona humana y de la sociedad, dentro de las' +
                                    ' exigencias de la verdad y del bien común.',
                                 alignment: 'justify'
                              }
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 table: {
                                    widths: [150, 60, '*', 90],
                                    body: [
                                       [
                                          {},
                                          {
                                             text: 'CARNET:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario.cliente
                                          },
                                          {
                                             image: this.cda64,
                                             alignment: 'center',
                                             rowSpan: 4,
                                             width: 90
                                          }
                                       ],
                                       [
                                          {},
                                          {
                                             text: 'NOMBRE:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario.nombre
                                          },
                                          {}
                                       ],
                                       [
                                          {},
                                          {
                                             text: 'FACULTAD:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario
                                                .unidadEducativa
                                          },
                                          {}
                                       ],
                                       [
                                          {},
                                          {
                                             text: 'CARRERA:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario.gradoAcademico
                                          },
                                          {}
                                       ]
                                    ]
                                 },
                                 layout: 'noBorders',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 16,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text:
                                    'TALONARIO UNICAMENTE PARA CICLO: ' +
                                    this.talonario.periodoLectivo,
                                 alignment: 'center',
                                 fontSize: '20',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text:
                                    'Talonario No. ' +
                                    this.talonario.codigoTalonario,
                                 fontSize: 10,
                                 alignment: 'right',
                                 bold: true,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ]
                        ]
                     },
                     layout: 'noBorders',
                     colSpan: 2
                  },
                  {}
               ])
            }

            // ------------------------------------ CUOTAS ----------------------------------------
            filteredDetalles.forEach((cuota) => {
               resultPage.push([
                  [
                     {
                        table: {
                           widths: [45, '*', '*'],
                           body: [
                              [
                                 {
                                    text: 'UNIVERSIDAD CATÓLICA\nDE EL SALVADOR',
                                    font: 'TimesNewRoman',
                                    fontSize: 10,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 'CARNET:',
                                 {
                                    text: this.talonario.cliente,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'ALUMNO:',
                                 {
                                    text: this.talonario.nombre,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CARRERA:',
                                 {
                                    text: this.talonario.gradoAcademico,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CICLO:',
                                 { text: this.talonario.periodoLectivo },
                                 {
                                    text: [
                                       { text: 'Pago N° ' },
                                       {
                                          text: cuota.cuota,
                                          fontSize: 18,
                                          bold: true
                                       }
                                    ]
                                 }
                              ],
                              [
                                 {
                                    text:
                                       'FACULTAD:\n' +
                                       this.talonario.unidadEducativa,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [60, 120, 5, '*'],
                                       body: getDetalleAranceles(cuota)
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: '_',
                                    color: 'white',
                                    fontSize: 6,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [15, 150, 110],
                                       body: [
                                          [
                                             {},
                                             {
                                                text: 'FECHA VENCIMIENTO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: cuota.fechaVencimiento,
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ],
                                          [
                                             {},
                                             {
                                                text: 'PAGO EXTEMPORANEO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: this.toFixedRegex(
                                                   this.pagoExtemporaneo,
                                                   2
                                                ),
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ]
                                       ]
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'NPE',
                                    fontSize: 9,
                                    bold: true,
                                    alignment: 'right'
                                 },
                                 {
                                    text: cuota.npe,
                                    fontSize: 9,
                                    bold: true,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 {
                                    text: Base64.decode(
                                       cuota.codigoDeBarrasCode128C
                                    ),
                                    font: 'CodigoDeBarra',
                                    colSpan: 3,
                                    fontSize: 10,
                                    alignment: 'center'
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: cuota.codigoDeBarras,
                                    alignment: 'center',
                                    fontSize: 7,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text:
                                       'Talonario No. ' +
                                       this.talonario.codigoTalonario,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'ALUMNO',
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              // SELLO CANCELADO
                              [
                                 {
                                    image: this.cuotaFacturada
                                       ? this.selloCancelado
                                       : this.emptyImage,
                                    absolutePosition: {
                                       x: 72,
                                       y: cuota.cuota == 1 ? 258 : 212
                                    },
                                    width: 170
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: this.cuotaFacturada
                                       ? this.fechaDePago.substr(0, 10)
                                       : '',
                                    bold: true,
                                    color: '#d9001d',
                                    fontSize: 14,
                                    absolutePosition: {
                                       x:
                                          this.fechaDePago == 'PAGO BANCO'
                                             ? 114
                                             : 120,
                                       y: cuota.cuota == 1 ? 312 : 266
                                    }
                                 },
                                 {},
                                 {}
                              ]
                           ]
                        },
                        margin: [0, 0, 15, 0],
                        layout: 'noBorders'
                     }
                  ],
                  [
                     {
                        table: {
                           widths: [45, '*', '*'],
                           body: [
                              [
                                 {
                                    text: 'UNIVERSIDAD CATÓLICA\nDE EL SALVADOR',
                                    font: 'TimesNewRoman',
                                    fontSize: 10,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 'CARNET:',
                                 {
                                    text: this.talonario.cliente,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'ALUMNO:',
                                 {
                                    text: this.talonario.nombre,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CARRERA:',
                                 {
                                    text: this.talonario.gradoAcademico,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CICLO:',
                                 { text: this.talonario.periodoLectivo },
                                 {
                                    text: [
                                       { text: 'Pago N° ' },
                                       {
                                          text: cuota.cuota,
                                          fontSize: 18,
                                          bold: true
                                       }
                                    ]
                                 }
                              ],
                              [
                                 {
                                    text:
                                       'FACULTAD:\n' +
                                       this.talonario.unidadEducativa,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [60, 120, 5, '*'],
                                       body: getDetalleAranceles(cuota)
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: '_',
                                    color: 'white',
                                    fontSize: 6,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [15, 150, 110],
                                       body: [
                                          [
                                             {},
                                             {
                                                text: 'FECHA VENCIMIENTO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: cuota.fechaVencimiento,
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ],
                                          [
                                             {},
                                             {
                                                text: 'PAGO EXTEMPORANEO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: this.toFixedRegex(
                                                   this.pagoExtemporaneo,
                                                   2
                                                ),
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ]
                                       ]
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'NPE',
                                    fontSize: 9,
                                    bold: true,
                                    alignment: 'right'
                                 },
                                 {
                                    text: cuota.npe,
                                    fontSize: 9,
                                    bold: true,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 {
                                    text: Base64.decode(
                                       cuota.codigoDeBarrasCode128C
                                    ),
                                    font: 'CodigoDeBarra',
                                    colSpan: 3,
                                    fontSize: 10,
                                    alignment: 'center'
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: cuota.codigoDeBarras,
                                    alignment: 'center',
                                    fontSize: 7,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text:
                                       'Talonario No. ' +
                                       this.talonario.codigoTalonario,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'UNIVERSIDAD',
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              // SELLO CANCELADO
                              [
                                 {
                                    image: this.cuotaFacturada
                                       ? this.selloCancelado
                                       : this.emptyImage,
                                    absolutePosition: {
                                       x: 302 + 72,
                                       y: cuota.cuota == 1 ? 258 : 212
                                    },
                                    width: 170
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: this.cuotaFacturada
                                       ? this.fechaDePago.substr(0, 10)
                                       : '',
                                    bold: true,
                                    color: '#d9001d',
                                    fontSize: 14,
                                    absolutePosition: {
                                       x:
                                          this.fechaDePago == 'PAGO BANCO'
                                             ? 302 + 114
                                             : 302 + 120,
                                       y: cuota.cuota == 1 ? 312 : 266
                                    }
                                 },
                                 {},
                                 {}
                              ]
                           ]
                        },
                        margin: [15, 0, 0, 0],
                        layout: 'noBorders'
                     }
                  ]
               ])
            })

            // ------------------------------------ REVERSO ----------------------------------------
            if (showPortadas) {
               resultPage.push([
                  {
                     table: {
                        widths: ['*', 175, '*'],
                        body: [
                           [
                              {
                                 text: 'TALONARIO DE PAGOS',
                                 font: 'TimesNewRoman',
                                 fontSize: 20,
                                 bold: true,
                                 alignment: 'center',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: 'INSTRUCCIONES',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              },
                              {
                                 image: this.logo64,
                                 alignment: 'center',
                                 width: 90,
                                 rowSpan: 2
                              },
                              {
                                 text: 'RECUERDE QUE',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              }
                           ],
                           [
                              {
                                 text:
                                    '1.    Los pagos deberán ser cancelados en el Banco que la Universidad autorice para realizar' +
                                    ' los cobros de los alumnos.',
                                 alignment: 'justify'
                              },
                              {},
                              {
                                 text:
                                    '1.    El vencimiento de las cuotas está programado en su talonario de pago y en el calendario' +
                                    ' académico. (Art. 29 del reglamento de evaluación).',
                                 alignment: 'justify'
                              }
                           ],
                           [
                              {
                                 text:
                                    '2.    Es responsabilidad del alumno verificar que el cajero firme y selle los recibos' +
                                    ' correspondiente a los pagos que realice en el Banco',
                                 alignment: 'justify'
                              },
                              {
                                 text: 'UNIVERSIDAD CATÓLICA DE EL SALVADOR',
                                 font: 'TimesNewRoman',
                                 bold: true,
                                 alignment: 'center',
                                 fontSize: 13
                              },
                              {
                                 text:
                                    '2.    Las matriculas y las cuotas canceladas en fechas posteriores a el vencimiento tendrán' +
                                    ' complemento de $12.00 y $12.00 respectivamente dicha medida será aplicada por el Banco automáticamente.',
                                 alignment: 'justify'
                              }
                           ],
                           [
                              {
                                 text:
                                    '\n3.    En caso de extravío del talonario de pagos deberá de solicitar una reposición' +
                                    ' en la colecturía de la Universidad, previo a pago respectivo.',
                                 alignment: 'justify'
                              },
                              {
                                 text:
                                    'By pass a Metapán y Carretera antigua a San Salvador\n' +
                                    'PBX: (503) 2484-0600\nFax: (503) 2441-2655,\nSanta Ana, El Salvador\n' +
                                    'Web: http://www.catolica.edu.sv\nE-mail: catolica@catolica.edu.sv',
                                 alignment: 'center',
                                 bold: true,
                                 fontSize: 11
                              },
                              { text: '', alignment: 'justify' }
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text:
                                    'Talonario No. ' +
                                    this.talonario.codigoTalonario,
                                 fontSize: 10,
                                 alignment: 'right',
                                 bold: true,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ]
                        ]
                     },
                     layout: 'noBorders',
                     pageBreak: 'before',
                     colSpan: 2
                  },
                  {}
               ])
            }

            return resultPage
         }

         var dd = {
            pageSize: {
               width: 612.0,
               height: 396.0
            },
            background: function () {
               return {
                  canvas: [
                     {
                        // COLOR DE PAGINA
                        type: 'rect',
                        x: 0,
                        y: 0,
                        w: 612.0,
                        h: 396.0,
                        color: '#FFF'
                     }
                  ]
               }
            },
            pageMargins: [20, 10],
            content: [
               {
                  table: {
                     heights: 390.0,
                     widths: ['*', '*'],
                     body: getTalonarioBody(portadas)
                  },
                  layout: {
                     hLineWidth: function () {
                        return 0
                     },
                     vLineWidth: function (i) {
                        return i === 1 ? 1 : 0
                     },
                     vLineColor: function () {
                        return 'gray'
                     },
                     vLineStyle: function () {
                        return { dash: { length: 6 } }
                     }
                  }
               }
            ],
            defaultStyle: {
               fontSize: 9
            }
         }

         var filename =
            filtroCuotas !== null
               ? `Cuota ${filtroCuotas} Ciclo ${this.talonario.periodoLectivo} ${this.talonario.cliente}.pdf`
               : `Talonario Ciclo ${this.talonario.periodoLectivo} ${this.talonario.cliente}.pdf`
         getReporteLib().then((res) => res.generarPDF(mode, dd, filename))
      },
      // Imprimir una cuota
      imprimirCuota(item) {
         this.periodoLectivo = item.periodoLectivo
         this.cuotaFacturada = item.facturado
         this.fechaDePago = item.fechaDePago
         // Obteniendo info de talonario
         this.showSnack('Generando documento...', 'snackInfo')
         this.getInfoTalonario().then(() => {
            this.generarReporteTalonario(
               this.$vuetify.breakpoint.smAndUp ? 'open' : 'save',
               false,
               item.cuota.toString()
            )
         })
      },
      // Mostrar el dialog de pago de cuota
      showPagoCuota(item) {
         this.npes = []
         this.idTransaccion = ''
         this.numeroCuota = item.cuota
         this.recargo = item.recargo
         this.cuotaDescuento = item.descuentoPorBeca
         this.cuotaTipoBeca = item.tipoBeca
         this.npes.push({ npe: item.npe })
         this.periodoLectivo = item.periodoLectivo
         this.totalPagar =
            item.tipoBeca == null
               ? this.numFormatIntl(Number(item.monto) + Number(item.recargo))
               : this.numFormatIntl(
                    Number(item.montoConDescuento) + Number(item.recargo)
                 )

         this.totalSinDescuento = this.numFormatIntl(
            Number(item.monto) + Number(item.recargo)
         )
         this.totalConDescuento = item.montoConDescuento
         this.contratoId = item.contratoId
         // Obteniendo info de talonario
         this.getInfoTalonario().then(() => {
            this.dialogPagoCuota = true
         })
      },
      // Mostrar el dialog de pago de idiomas
      showPagoIdioma(item) {
         this.listarProgramas(item.idioma == 'EN' ? 2 : 6)
         this.idiomaVencido = item.vencido
         this.idiomaMesesDesdeUltimoModulo = item.mesesDesdeUltimoModulo
         this.idiomaLimiteMesesInacividad = item.limiteMesesInactividad
         this.idiomaNivel = item.ultimoNivelAprobado
         this.idiomaMonto = item.monto
         this.idiomaProgramaId = ''
         this.idiomaCodigoAlumno = item.codigoIdiomas
         this.idiomaCuota = item.cuota
         this.idiomaProgramaDesc = item.programaDescripcion
         this.idiomaProgramaCodigo = item.programaCodigo
         this.idiomaNombre =
            item.idioma == 'EN'
               ? 'Inglés'
               : item.idioma == 'FR'
               ? 'Francés'
               : item.idioma
         //this.totalPagar = this.idiomaMonto
         this.dialogPagoIdioma = true

         axios
            .get('/api/pagosIdiomas/recargo')
            .then((response) => {
               this.recargoIdiomas = response.data.recargo
            })
            .catch((error) => {
               console.log('error:' + error.response)
            })
      },
      closePagoIdioma() {
         this.selectPrograma = ''
         this.idiomaVencido = false
         this.idiomaMesesDesdeUltimoModulo = 0
         this.idiomaLimiteMesesInacividad = 0
         this.nivelDeshabilitado = false
         this.cupoAgotado = false
         this.disablePagoIdioma = false
         this.idiomaProgramaDesc = ''
         this.idiomaNivel = ''
         this.idiomaMonto = ''
         this.idiomaProgramaId = ''
         this.idiomaNombre = ''
         this.$refs.formPagoIdioma.reset()
         this.dialogPagoIdioma = false
         this.totalPagar = ''
         this.recargoIdiomas = 0
      },
      // Cerrar el dialog de pago de cuota
      closePagoCuota() {
         this.cuotaDescuento = 0
         this.totalConDescuento = 0
         this.totalSinDescuento = 0
         this.cuotaTipoBeca = null
         this.numeroCuota = ''
         this.recargo = 0
         this.periodoLectivo = ''
         this.idTransaccion = ''
         this.totalPagar = ''
         this.talonario = []
         this.aranceles = []
         this.npes = []
         this.dialogPagoCuota = false
      },
      // Mostrar el dialog de pago de cuota multiple
      showPagoCuotaMultiple() {
         this.totalPagar = 0
         this.npes = []

         // Por cada cuota seleccionada armar un detalle de pago
         this.cuotasSeleccionadas.forEach((cuota) => {
            // Acumulando total a pagar
            this.totalPagar =
               cuota.tipoBeca == null
                  ? this.totalPagar +
                    Number(cuota.monto) +
                    Number(cuota.recargo)
                  : this.totalPagar +
                    Number(cuota.montoConDescuento) +
                    Number(cuota.recargo)

            // Preparando arreglo de npes a enviar en transaccion
            this.npes.push({ npe: cuota.npe })

            // Creando detalle de pago
            this.cuotasPagoMultiple.push({
               descripcion:
                  'Cuota # ' +
                  cuota.cuota +
                  ' - Ciclo: ' +
                  cuota.periodoLectivo,
               precioUnitario:
                  cuota.tipoBeca == null
                     ? Number(cuota.monto) + Number(cuota.recargo)
                     : Number(cuota.montoConDescuento) + Number(cuota.recargo)
            })
         })

         this.dialogPagoCuotaMultiple = true
      },
      // Cerrar el dialog de pago de cuota multiple
      closePagoCuotaMultiple() {
         this.idTransaccion = ''
         this.totalPagar = ''
         this.npes = []
         this.cuotasPagoMultiple = []
         this.dialogPagoCuotaMultiple = false
      },
      // Obtener informacion del talonario de la cuota seleccionada
      getInfoTalonario() {
         return new Promise((resolve) => {
            axios
               .get(
                  'api/Contrato?Carnet=' +
                     this.publicUser.carnet +
                     '&Ciclo=' +
                     this.periodoLectivo
               )
               .then((response) => {
                  this.talonario = response.data[0]
                  this.totalConDescuento = 0
                  this.aranceles = []
                  this.talonario.detalleContrato.forEach((el) => {
                     //console.log(el)
                     this.aranceles.push({
                        articulo: el.articulo,
                        aplicaBeca: el.aplicaBeca,
                        descripcion: el.descripcion,
                        tipoCobro: el.tipoCobro,
                        precioUnitario:
                           el.aplicaBeca && this.cuotaTipoBeca != null
                              ? Number(el.precioUnitario) -
                                Number(el.precioUnitario) *
                                   (Number(this.cuotaDescuento) / 100)
                              : el.precioUnitario,
                        montoImpuesto: el.montoImpuesto,
                        extraordinaria: el.extraordinaria,
                        procesoGraduacion: el.procesoGraduacion,
                        contratoId: el.contratoId
                     })
                     this.totalConDescuento =
                        Number(el.totalConDescuento) +
                        (el.aplicaBeca && this.cuotaTipoBeca != null
                           ? Number(el.precioUnitario) *
                             (Number(this.cuotaDescuento) / 100)
                           : el.precioUnitario)
                  })
               })
               .catch(() => {
                  //console.log(error)
               })
               .finally(() => {
                  resolve('ok')
               })
         })
      },
      // Ir a step 2
      listarCuotas() {
         this.loadingCuotas = true
         this.listarCuotasPendientes()
         this.stepNum = 2
      },
      // Listar cuotas del pharo que aun no esten facturadas
      listarCuotasPendientes() {
         this.detallesPendientes = []
         this.obtenerEstadoSistema()
         axios
            .get(
               `api/Contrato/Cuotas?Carnet=${this.publicUser.carnet}&IncluirDeuda=true&Actual=true`
            )
            .then((response) => {
               this.detallesPendientes = response.data
               this.loadingCuotas = false
            })
            .catch(() => {
               //console.log(error)
            })
      },
      validarAlumnoMadre() {
         var ultimoCiclo = ''
         // Validar si es alumno de COLEGIO MADRE DE LA IGLESIA
         axios
            .get(`api/Cliente?Carnet=${this.publicUser.carnet}`)
            .then((response) => {
               if (response.data != []) {
                  // Validar tipo alumno madre
                  if (response.data[0].tipoId == 2) {
                     this.alumnoMadre = true
                  } else {
                     this.alumnoMadre = false
                  }
               } else {
                  this.alumnoMadre = false
               }
            })
            .catch(() => {
               // Pass
            })
            .finally(() => {
               if (this.alumnoMadre) {
                  axios
                     .get(
                        `api/Contrato/Cuotas?Carnet=${this.publicUser.carnet}&IncluirDeuda=true&Actual=true`
                     )
                     .then((response) => {
                        ultimoCiclo =
                           response.data[response.data.length - 1]
                              .periodoLectivo
                     })
                     .catch(() => {
                        //console.log(error)
                     })
                     .finally(() => {
                        axios
                           .get(
                              `api/Contrato?Carnet=${this.publicUser.carnet}&Ciclo=${ultimoCiclo}`
                           )
                           .then((response) => {
                              if (response.data != []) {
                                 this.carreraNombre =
                                    response.data[0].gradoAcademico.trim()
                              } else {
                                 this.carreraNombre = 'CARRERA NO ENCONTRADA'
                              }
                           })
                           .catch(() => {
                              //console.log(error)
                           })
                     })
               }
            })
      },
      irPagoAranceles() {
         axios
            .get('api/Arancel/publicos?EsPublico=true')
            .then((response) => {
               this.cmbArancel = response.data.sort(this.dynamicSort('nombre'))
            })
            .catch(() => {
               //console.log(error)
            })
            .finally(() => {
               this.selectArancel = ''
               this.detallesAranceles = []
               this.stepNum = 3
            })
      },
      // Generar concepto comprobantes
      generarConcepto(item) {
         if (item.tipoPago == 'Cuota' && item.cantidad > 1) {
            return (
               'Pago de cuotas multiple. ' +
               item.concepto +
               ' + otras ' +
               (item.cantidad - 1).toString() +
               ' cuotas.'
            )
         } else if (item.tipoPago == 'Arancel' && item.cantidad > 1) {
            return item.concepto + ' x ' + item.cantidad
         } else if (
            item.tipoPago == 'Arancel' &&
            item.detalle[0].materiaCodigo != ''
         ) {
            return item.concepto + ' ' + item.detalle[0].materiaCodigo
         } else {
            return item.concepto
         }
      },
      // Listar pagos
      listarPagos() {
         this.stepNum = 5
         this.loadingPagos = true
         this.pagosRegistrados = []
         axios
            .get(`api/pago/comprobantes?carnet=${this.publicUser.carnet}`)
            .then((response) => {
               this.pagosRegistrados = response.data
               this.loadingPagos = false
            })
            .catch(() => {
               //console.log(error)
            })
      },
      // Copiar al portapapeles
      copyToClip(value) {
         this.copyStringToClipboard(value)
         this.showSnack('NPE copiado al portapapeles', 'snackInfo')
      },
      // Obtener estado del sistema
      obtenerEstadoSistema() {
         // Obtener configuracion
         axios
            .get('api/Configuracion/pagosEnLinea')
            .then((response) => {
               //console.log(response)
               if (response.data.valor == 'true') {
                  this.pagosDeshabilitados = false
               } else {
                  this.pagosDeshabilitados = true
               }
            })
            .catch(() => {
               //console.log(error)
            })
         // Estado de pagos de idiomas habilitados
         axios
            .get('api/Configuracion/pagosIdiomas')
            .then((response) => {
               //console.log(response)
               if (response.data.valor == 'true') {
                  this.pagosIdiomasDeshabilitados = false
               } else {
                  if (this.checkRangoAlto()) {
                     this.pagosIdiomasDeshabilitados = false
                  } else {
                     this.pagosIdiomasDeshabilitados = true
                  }
               }
            })
            .catch(() => {
               //console.log(error)
            })
         // Mensaje desde DB
         axios
            .get('api/Configuracion/mensaje')
            .then((response) => {
               //console.log('Mostrar: ' + me.mostrarMensaje)
               if (
                  response.data.valor.trim() != '' &&
                  response.data.valor != null
               ) {
                  this.mensajeImportante = response.data.valor
                  this.mostrarMensaje = true
               } else {
                  this.mostrarMensaje = false
                  this.mensajeImportante = ''
               }
            })
            .catch(() => {
               //console.log(error)
            })
      },
      // Validar clic de checkbox
      checkCondition(event, item, isSelected) {
         for (let i = 0; i < this.detallesPendientes.length; i++) {
            const el = this.detallesPendientes[i]
            // Iterar hasta item seleccionado validando que la cuota no este
            // facturada, sea diferente del npe seleccionado y no se encuentre en el arreglo
            // de cuotas seleccionadas aun
            if (
               !el.facturado &&
               el.npe != item.npe &&
               !this.cuotasSeleccionadas.includes(el)
            ) {
               this.showSnack(
                  'No puedes dejar cuotas intermedias sin marcar',
                  'snackWarning'
               )
               return false
            }
            if (el.npe == item.npe) {
               // Validar que solo se pueda desmarcar ultima cuota del arreglo
               if (
                  isSelected &&
                  this.cuotasSeleccionadas[
                     this.cuotasSeleccionadas.length - 1
                  ] != item
               ) {
                  this.showSnack(
                     'No puedes desmarcar cuotas intermedias',
                     'snackWarning'
                  )
                  return true
               } else {
                  return event
               }
               break
            }
         }
      },
      // Logica de disabled de datatable
      disabledCuota(item, ignoreFacturado = false) {
         // Si tiene una cuota con beca pagada y aun no envia el formulario deshabilitar todo
         if (this.debeComprobantes) {
            return true
         }

         // Si ya pago deshabilitar
         if (item.facturado && !ignoreFacturado) {
            return true
         } else if (item.facturado && ignoreFacturado) {
            return false
         }

         // Si tiene cuotas de ciclos pasados, deshabilitar periodo actual
         if (
            this.detallesPendientes.some(
               (c) => c.periodoLectivo != this.cicloActual && !c.facturado
            )
         ) {
            if (item.periodoLectivo == this.cicloActual) {
               // Si es del ciclo actual deshabilitar
               return true
            } else {
               // Si no deshabilitar en orden
               for (let i = 0; i < this.detallesPendientes.length; i++) {
                  if (this.detallesPendientes[i].npe == item.npe) {
                     // Validando si el primer item ya esta facturado
                     if (i == 0 && this.detallesPendientes[i].facturado) {
                        return true
                     }
                     // Validando a partir del segundo item que el anterio ya este facturado
                     if (i > 0 && !this.detallesPendientes[i - 1].facturado) {
                        return true
                     }
                  }
               }
            }
         } else {
            // Si solo tiene cuotas del periodo actual, permitir todo
            // siempre y cuando ya este pagada la matricula
            if (
               this.detallesPendientes.some(
                  (c) =>
                     c.periodoLectivo == this.cicloActual &&
                     !c.facturado &&
                     c.cuota == 1
               )
            ) {
               return item.cuota > 1 ? true : false
            } else {
               return false
            }
         }
      },
      disabledArancel(item) {
         // Si son complementarios validar pago y disabled
         if (item.codigo == '01-023') {
            // Si ya pago deshabilitar
            if (item.facturado) {
               return true
            }
         } else {
            // Aranceles normales siempre estan disponibles
            return false
         }
      },
      // Inicializa proceso de pago al hacer clic en boton PAGAR
      initPago() {
         if (this.$refs.formPagoArancel.validate()) {
            this.insertPagoRes = []
            this.popupRes = []
            this.showSnack(
               'Abriendo ventana de pago, por favor espere...',
               'snackInfo'
            )
            this.dialogEsperaColor = 'primary'
            this.dialogEsperaMessage = 'Esperando respuesta de ventana de pago.'
            this.dialogEsperaSubMessage =
               'No cierre esta pestaña o el navegador durante su proceso de pago. Si cerró la ventana de pago y desea quitar este mensaje, haga clic aquí: '
            this.dialogEsperaPago = true

            // Generar un id de transaccion
            axios
               .post('api/Pago/transaccion', {
                  token: process.env.VUE_APP_TOKEN_TRANS
               })
               .then((response) => {
                  if (response.status == 201) {
                     this.idTransaccion = response.data.transactionId
                     // Al crearse un idTransaccion se insertan los pagos vacios a espera de respuesta de Sersfisa
                     if (this.stepNum == 2) {
                        // POST DE PAGO DE CUOTAS
                        axios
                           .post('api/Pago/cuota', {
                              transaccionId: this.idTransaccion,
                              referencia: null,
                              autorizacion: null,
                              responseCode: null,
                              responseName: null,
                              maskPan: null,
                              cuotas: this.npes,
                              botonDePagoId: 1
                           })
                           .then((response) => {
                              if (response.status == 201) {
                                 // Llamar a popup de pago de serfinsa
                                 this.insertPagoRes = response.data
                                 this.tokenSerfinsa = this.tokenContabilidad
                                 setTimeout(() => {
                                    $('#btPagar').trigger('click')
                                 }, 300)
                              } else {
                                 this.showSnack(
                                    'El sistema no pudo inicializar su proceso de pago.',
                                    'snackError'
                                 )
                              }
                           })
                           .catch(() => {
                              this.showSnack(
                                 'El sistema no pudo inicializar su proceso de pago.',
                                 'snackError'
                              )
                           })
                     } else if (this.stepNum == 3) {
                        var facultadId = this.cmbCarreraArancel.find(
                           (obj) =>
                              obj.carreraUniversitariaId ==
                              this.selectCarreraUniversitaria
                        )

                        facultadId =
                           facultadId != undefined
                              ? facultadId.unidadAcademicaId
                              : null

                        //POST DE PAGO DE ARANCEL
                        axios
                           .post('api/Pago/otroarancel', {
                              alumnoCarnet: this.publicUser.carnet,
                              arancelCodigo: this.arancelCodigo,
                              transaccionId: this.idTransaccion,
                              referencia: null,
                              autorizacion: null,
                              responseCode: null,
                              responseName: null,
                              maskPan: null,
                              seccion: this.seccion,
                              periodoDeCiclo: this.periodoDeCiclo,
                              decanatoCodigo: this.arancelReqFacultad
                                 ? facultadId
                                 : this.decanatoCodigo,
                              materiaCodigo: this.materiaCodigo,
                              cantidad: this.itemCantidad,
                              programaIdiomaId: this.selectProgramaArancel,
                              botonDePagoId: 1
                           })
                           .then((response) => {
                              if (response.status == 201) {
                                 // Llamar a popup de pago de serfinsa
                                 this.insertPagoRes = response.data
                                 this.tokenSerfinsa = this.tokenContabilidad
                                 setTimeout(() => {
                                    $('#btPagar').trigger('click')
                                 }, 300)
                              } else {
                                 this.showSnack(
                                    'El sistema no pudo inicializar su proceso de pago.',
                                    'snackError'
                                 )
                              }
                           })
                           .catch(() => {
                              this.dialogEsperaPago = false
                              this.showSnack(
                                 'No es posible registrar su intento de pago.',
                                 'snackError'
                              )
                           })
                     } else if (this.stepNum == 4) {
                        //POST DE PAGO DE IDIOMAS
                        axios
                           .post('api/Pago/otroarancel', {
                              alumnoCarnet: this.publicUser.carnet,
                              arancelCodigo: null,
                              transaccionId: this.idTransaccion,
                              referencia: null,
                              autorizacion: null,
                              responseCode: null,
                              responseName: null,
                              maskPan: null,
                              seccion: null,
                              periodoDeCiclo: 0,
                              decanatoCodigo: null,
                              materiaCodigo: null,
                              cantidad: 1,
                              programaIdiomaId: this.selectPrograma,
                              nivel: this.idiomaNivel,
                              botonDePagoId: 2
                           })
                           .then((response) => {
                              if (response.status == 201) {
                                 // Llamar a popup de pago de serfinsa
                                 this.insertPagoRes = response.data
                                 this.tokenSerfinsa = this.tokenIdiomas
                                 setTimeout(() => {
                                    $('#btPagar').trigger('click')
                                 }, 300)
                              } else {
                                 this.showSnack(
                                    'El sistema no pudo inicializar su proceso de pago.',
                                    'snackError'
                                 )
                              }
                           })
                           .catch((error) => {
                              this.dialogEsperaPago = false
                              console.log(error.response)
                              this.showSnack(
                                 'No es posible registrar su intento de pago.',
                                 'snackError'
                              )
                           })
                     }
                  }
               })
               .catch(() => {
                  this.dialogEsperaPago = false
                  this.showSnack(
                     'Sistema en mantenimiento, intenta mas tarde',
                     'snackError'
                  )
               })
         }
      },
      // Muestra snack
      showSnack(text, color) {
         this.snackText = text
         this.snackColor = color
         this.snackbar = true
      },
      // Generar id para pruebas de transaccion
      makeid(length) {
         var result = ''
         var characters =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
         var charactersLength = characters.length
         for (var i = 0; i < length; i++) {
            result += characters.charAt(
               Math.floor(Math.random() * charactersLength)
            )
         }
         return result
      },
      // Imprimir comprobante de pago
      imprimirComprobantePago(res, timeout = 4500, mode = 'save', tipo) {
         return new Promise((resolve) => {
            this.detallesComprobantes = []
            if (tipo == 'arancel') {
               // Para aranceles
               this.npe = res.npe
               this.fechaDePago = this.popupRes.Fecha
               this.autorizacion = this.popupRes.NumeroAutorizacion
               this.referencia = this.popupRes.NumeroReferencia
               this.detallePagoArancel = []
               this.detallePagoArancel.push({
                  codigo: res[0].arancelCodigo,
                  itemCantidad: this.itemCantidad,
                  descripcion: res[0].arancel,
                  precioUnitario: Number(res[0].monto),
                  materiaCodigo: this.materiaCodigo
               })
            } else {
               // Para cuotas
               res.forEach((el) => {
                  this.detallesComprobantes.push(el.npe)
               })
               this.fechaDePago = this.popupRes.Fecha
               this.autorizacion = this.popupRes.NumeroAutorizacion
               this.referencia = this.popupRes.NumeroReferencia
            }
            setTimeout(() => {
               this.generarComprobantePago(mode, tipo)
               resolve('ok')
            }, timeout)
         })
      },
      // Imprimir comprobante de pago
      reImprimirComprobantePago(item) {
         this.detallesComprobantes = []
         this.showSnack(
            'Generando comprobante de pago, por favor espere...',
            'snackInfo'
         )
         this.fechaDePago = item.fecha
         this.autorizacion = item.autorizacion
         this.referencia = item.referencia
         this.itemPrecio = Number(item.detalle[0].monto)
         this.materiaCodigo = item.detalle[0].materiaCodigo
         this.materiaNombre = ''
         this.itemCantidad =
            item.tipoPago.toLowerCase() == 'arancel' ? item.cantidad : 1
         this.totalPagar = Number(item.total)

         if (item.tipoPago.toLowerCase() == 'arancel') {
            // Para aranceles
            this.idiomaNivel =
               item.detalle[0].nivel != '' ? item.detalle[0].nivel : ''
            this.idiomaProgramaDesc =
               item.detalle[0].programa != '' ? item.detalle[0].programa : ''
            this.idiomaNombre =
               item.detalle[0].idioma != '' ? item.detalle[0].idioma : ''

            this.npe = ''
            this.detallePagoArancel = []
            this.detallePagoArancel.push({
               codigo: item.detalle[0].arancelCodigo,
               itemCantidad: this.itemCantidad,
               descripcion: item.detalle[0].arancel,
               precioUnitario: Number(item.detalle[0].monto),
               materiaCodigo: this.materiaCodigo
            })
         } else {
            // Para cuotas
            this.detallesPendientes = []
            this.detallesComprobantes = []
            item.detalle.forEach((el) => {
               this.detallesPendientes.push({
                  cuota: el.numeroCuota,
                  npe: el.npe,
                  periodoLectivo: el.periodoLectivo,
                  monto: Number(el.monto),
                  montoConDescuento: Number(el.monto),
                  descuentoPorBeca: el.descuento,
                  tipoBeca: el.tipoBeca,
                  recargo: 0
               })
               this.detallesComprobantes.push(el.npe)
            })
         }
         setTimeout(() => {
            this.generarComprobantePago('save', item.tipoPago.toLowerCase())
         }, 300)
      },
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Genera comprobante de pago
      generarComprobantePago(mode, tipo) {
         var pageHeight = 396.0
         if (tipo != 'arancel' && this.detallesComprobantes.length > 4) {
            pageHeight = 460.0
         }

         const getDetalleAranceles = () => {
            var result = []
            var fS = this.aranceles.length < 5 ? 8 : 7
            result.push([
               {
                  text: 'CODIGO',
                  alignment: 'right',
                  bold: true,
                  fontSize: fS
               },
               {
                  text: 'CANT.',
                  alignment: 'right',
                  bold: true,
                  fontSize: fS
               },
               {
                  text: 'DESCRIPCION',
                  bold: true,
                  fontSize: fS
               },
               { text: ' ', fontSize: fS },
               {
                  text: 'PRECIO',
                  fontSize: fS,
                  bold: true,
                  margin: [0, 0, 35, 0],
                  alignment: 'right'
               }
            ])

            this.detallePagoArancel.forEach((arancel) => {
               result.push([
                  {
                     text: arancel.codigo,
                     alignment: 'right',
                     fontSize: fS
                  },
                  {
                     text: this.itemCantidad,
                     alignment: 'right',
                     fontSize: fS
                  },
                  {
                     text:
                        arancel.descripcion +
                        ' ' +
                        (arancel.materiaCodigo ? arancel.materiaCodigo : '') +
                        (this.idiomaProgramaDesc != null
                           ? `, ${this.idiomaProgramaDesc}, NIVEL ${this.idiomaNivel}`
                           : ''),
                     fontSize: fS
                  },
                  { text: '$', fontSize: fS },
                  {
                     text: this.toFixedRegex(Number(arancel.precioUnitario), 2),
                     fontSize: fS,
                     margin: [0, 0, 35, 0],
                     alignment: 'right'
                  }
               ])
            })

            if (this.recargoIdiomas !== undefined) {
               if (this.recargoIdiomas > 0) {
                  result.push([
                     {},
                     {},
                     {
                        text: 'Recargo por pago extemporáneo'.toUpperCase(),
                        alignment: 'left',
                        fontSize: fS
                     },
                     { text: '$', fontSize: 10 },
                     {
                        text: this.recargoIdiomas,
                        alignment: 'left',
                        fontSize: fS
                     }
                  ])
               }
            }

            result.push([
               {
                  text: 'TOTAL A PAGAR ',
                  fontSize: 10,
                  margin: [0, 0, 15, 0],
                  bold: true,
                  alignment: 'right',
                  colSpan: 3
               },
               {},
               {},
               {
                  text: '$',
                  fontSize: 10,
                  bold: true
               },
               {
                  text: this.numFormatIntl(this.totalPagar),
                  fontSize: 10,
                  bold: true,
                  margin: [0, 0, 35, 0],
                  alignment: 'right'
               }
            ])
            return result
         }

         const getDetalleCoutas = () => {
            var result = []
            var fS = this.detallesComprobantes.length < 4 ? 8 : 7
            this.detallesPendientes.forEach((cuota) => {
               if (this.detallesComprobantes.includes(cuota.npe)) {
                  result.push([
                     {
                        text: `CUOTA ${cuota.cuota}`,
                        alignment: 'right',
                        fontSize: fS
                     },
                     {
                        text: `CICLO ${cuota.periodoLectivo} ${
                           cuota.tipoBeca == null
                              ? ''
                              : `CON BECA ${Number(cuota.descuentoPorBeca)}%`
                        }`,
                        fontSize: fS
                     },
                     { text: '$', fontSize: fS },
                     {
                        text:
                           cuota.tipoBeca == null
                              ? this.toFixedRegex(
                                   Number(cuota.monto) + Number(cuota.recargo),
                                   2
                                )
                              : this.toFixedRegex(
                                   Number(cuota.montoConDescuento) +
                                      Number(cuota.recargo),
                                   2
                                ),
                        fontSize: fS,
                        margin: [0, 0, 35, 0],
                        alignment: 'right'
                     }
                  ])
               }
            })

            result.push([
               {
                  text: 'TOTAL A PAGAR',
                  fontSize: 10,
                  margin: [0, 0, 15, 0],
                  bold: true,
                  alignment: 'right',
                  colSpan: 2
               },
               {},
               {
                  text: '$',
                  fontSize: 10,
                  bold: true
               },
               {
                  text: this.numFormatIntl(this.totalPagar),
                  fontSize: 10,
                  bold: true,
                  margin: [0, 0, 35, 0],
                  alignment: 'right'
               }
            ])
            return result
         }

         const getCuotasNpes = () => {
            var res = ''
            this.detallesComprobantes.forEach((el) => {
               //console.log(el)
               res = res + el + '\n'
            })
            return res.trim()
         }

         var dd = {
            pageSize: {
               width: 306.0,
               height: pageHeight
            },
            background: () => {
               return {
                  image: this.selloCanceladoNoDate,
                  absolutePosition: {
                     x: 72,
                     y: pageHeight - 116
                  },
                  width: 170,
                  opacity: 0.3
               }
            },
            watermark: {
               text: 'CANCELADO',
               color: 'red',
               angle: -35,
               fontSize: 40,
               opacity: 0.3,
               bold: true
            },
            pageMargins: [20, 20],
            // Pie de pagina con numero de páginas
            footer: function (currentPage, pageCount) {
               return {
                  margin: [20, 0],
                  fontSize: 10,
                  alignment: 'right',
                  text: `Página ${currentPage.toString()} de ${pageCount}`
               }
            },
            content: [
               {
                  table: {
                     heights: 390.0,
                     widths: ['*'],
                     body: [
                        [
                           {
                              table: {
                                 widths: [60, '*', '*'],
                                 body: [
                                    [
                                       {
                                          text: 'UNIVERSIDAD CATÓLICA\nDE EL SALVADOR',
                                          font: 'TimesNewRoman',
                                          fontSize: 10,
                                          bold: true,
                                          alignment: 'center',
                                          colSpan: 3
                                       },
                                       {},
                                       {}
                                    ],
                                    [
                                       'CARNET:',
                                       {
                                          text: this.publicUser.carnet,
                                          colSpan: 2
                                       },
                                       {}
                                    ],
                                    [
                                       'DUI:',
                                       {
                                          text: this.publicUser.dui,
                                          colSpan: 2
                                       },
                                       {}
                                    ],
                                    [
                                       'CLIENTE:',
                                       {
                                          text: this.nombreCompleto,
                                          colSpan: 2
                                       },
                                       {}
                                    ],
                                    [
                                       {
                                          text: '_',
                                          color: 'white',
                                          fontSize: 6,
                                          colSpan: 3
                                       },
                                       {},
                                       {}
                                    ],
                                    [
                                       {
                                          text:
                                             'MANDAMIENTO DE PAGO DE ' +
                                             (tipo == 'arancel'
                                                ? 'ARANCELES'
                                                : 'CUOTAS'),
                                          bold: true,
                                          fontSize: 10,
                                          alignment: 'center',
                                          colSpan: 3
                                       },
                                       {},
                                       {}
                                    ],
                                    [
                                       {
                                          table: {
                                             widths:
                                                tipo == 'arancel'
                                                   ? [35, 25, 120, 5, '*']
                                                   : [60, 120, 5, '*'],
                                             body:
                                                tipo == 'arancel'
                                                   ? getDetalleAranceles()
                                                   : getDetalleCoutas()
                                          },
                                          layout: 'noBorders',
                                          colSpan: 3
                                       },
                                       {},
                                       {}
                                    ],
                                    [
                                       {
                                          text: [
                                             {
                                                text:
                                                   this.materiaCodigo != '' &&
                                                   this.materiaNombre != ''
                                                      ? this.materiaCodigo +
                                                        ': '
                                                      : '',
                                                bold: true
                                             },
                                             this.materiaCodigo != '' &&
                                             this.materiaNombre != ''
                                                ? this.materiaNombre
                                                : ''
                                          ],
                                          fontSize:
                                             this.materiaCodigo != '' &&
                                             this.materiaNombre != ''
                                                ? 8
                                                : 1,
                                          align: 'justify',
                                          margin: [20, 0],
                                          colSpan: 3
                                       },
                                       {},
                                       {}
                                    ],
                                    [
                                       {
                                          text: '_',
                                          color: 'white',
                                          fontSize: 3,
                                          colSpan: 3
                                       },
                                       {},
                                       {}
                                    ],
                                    [
                                       {
                                          text: tipo == 'arancel' ? '' : 'NPE',
                                          fontSize: tipo == 'arancel' ? 1 : 9,
                                          bold: true,
                                          alignment: 'right'
                                       },
                                       {
                                          text:
                                             tipo == 'arancel'
                                                ? this.npe
                                                : getCuotasNpes(),
                                          fontSize: 8,
                                          bold: true,
                                          colSpan: 2
                                       },
                                       {}
                                    ],
                                    [
                                       {
                                          text: 'Referencia',
                                          fontSize: 9,
                                          bold: true,
                                          alignment: 'right'
                                       },
                                       {
                                          text: this.referencia,
                                          fontSize: 9,
                                          bold: true,
                                          colSpan: 2
                                       },
                                       {}
                                    ],
                                    [
                                       {
                                          text: 'Autorización',
                                          fontSize: 9,
                                          bold: true,
                                          alignment: 'right'
                                       },
                                       {
                                          text: this.autorizacion,
                                          fontSize: 9,
                                          bold: true,
                                          colSpan: 2
                                       },
                                       {}
                                    ],
                                    [
                                       {
                                          text: 'Fecha de pago',
                                          fontSize: 9,
                                          bold: true,
                                          alignment: 'right'
                                       },
                                       {
                                          text: this.fechaDePago,
                                          fontSize: 9,
                                          bold: true,
                                          colSpan: 2
                                       },
                                       {}
                                    ],
                                    [
                                       {
                                          text: 'COMPROBANTE CLIENTE',
                                          bold: true,
                                          alignment: 'center',
                                          colSpan: 3
                                       },
                                       {},
                                       {}
                                    ],
                                    [
                                       {
                                          text: 'El presente mandamiento, no sustituye facturas o comprobantes de crédito fiscal.',
                                          bold: true,
                                          alignment: 'center',
                                          colSpan: 3,
                                          margin: [20, 5]
                                       },
                                       {},
                                       {}
                                    ]
                                 ]
                              },
                              margin: [0, 0, 15, 0],
                              layout: 'noBorders'
                           }
                        ]
                     ]
                  },
                  layout: 'noBorders'
               }
            ],
            defaultStyle: {
               fontSize: 9
            }
         }

         var fechaFile = this.fechaDePago.includes('/')
            ? this.parseDate(this.fechaDePago.substr(0, 10))
            : this.fechaDePago.substr(0, 10)

         var filename =
            'Comprobante ' +
            this.autorizacion +
            ' Colecturia Externa ' +
            fechaFile +
            '.pdf'
         getReporteLib().then((res) => res.generarPDF(mode, dd, filename))
      },
      // Listar comboboxes
      listarComboboxes() {
         axios
            .get('api/Arancel/publicos?EsPublico=true')
            .then((response) => {
               this.cmbArancel = response.data.sort(this.dynamicSort('nombre'))
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('api/general/CarreraUniversitaria')
            .then((response) => {
               this.cmbCarrera = response.data
               this.cmbCarreraArancel = response.data.filter((obj) => {
                  return (
                     obj.unidadAcademicaId == 100 ||
                     obj.unidadAcademicaId == 500 ||
                     obj.unidadAcademicaId == 600 ||
                     obj.unidadAcademicaId == 800 ||
                     obj.unidadAcademicaId == 900
                  )
               })
            })
            .catch(() => {
               //console.log(error)
            })
            .finally(() => {
               if (this.publicUser != null) {
                  this.validarAlumnoMadre()
               }
            })
         axios
            .get(
               'api/general/UnidadAcademica?UnidadAcademicaId=100,500,600,800,900,999'
            )
            .then((response) => {
               this.cmbUnidadesAcademicas = response.data
            })
            .catch(() => {
               //console.log(error)
            })
      },
      // Cerrar ventana de dialogo
      close() {
         this.dialogDemoTalonario = false
         this.clear()
         setTimeout(() => {
            this.editedIndex = -1
         }, 300)
      },
      // Limpiar formularios y componentes
      clear() {
         this.talonario = []
         this.contratoId = ''
         this.editedIndex = -1
         this.selectProgramaArancel = null
      },
      // Get base64 image
      toDataURL(url) {
         return new Promise(function (resolve) {
            var xhr = new XMLHttpRequest()
            xhr.onload = function () {
               var reader = new FileReader()
               reader.onloadend = function () {
                  resolve(reader.result)
               }
               reader.readAsDataURL(xhr.response)
            }
            xhr.open('GET', url)
            xhr.responseType = 'blob'
            xhr.send()
         })
      },
      // Mostrar el dialog de pago de arancel
      showPagoArancel(item) {
         if (this.$refs.formPagoProgramaArancel.validate()) {
            this.totalPagar = item.precio
            this.itemCodigo = item.codigo
            this.arancelCantidad = item.porCantidad
            this.arancelDescripcion = item.descripcion
            this.arancelReqFacultad = item.requiereFacultad
            this.detallePagoArancel = []
            this.itemCantidad = 1
            this.itemPrecio = Number(item.precio)

            // Crear arreglo de detalle de pago arancel
            var extra =
               item.codigoMateria === undefined ? '' : ' ' + item.codigoMateria
            this.detallePagoArancel.push({
               codigo: item.codigo,
               descripcion: item.nombre + extra,
               precioUnitario: item.precio
            })

            // Preparar variables a enviar en post
            this.arancelCodigo = item.codigo
            this.seccion = item.seccion === undefined ? '' : item.seccion
            this.periodoDeCiclo =
               item.periodoDeCiclo === undefined ? 0 : item.periodoDeCiclo
            this.decanatoCodigo =
               item.codigoDecanato === undefined ? '' : item.codigoDecanato
            this.materiaCodigo =
               item.codigoMateria === undefined ? '' : item.codigoMateria
            this.materiaNombre = item.materia === undefined ? '' : item.materia

            // Mostrar dialog de detalles de pago
            this.dialogPagoArancel = true
         }
      },
      // Cerrar el dialog de pago de arancel
      closePagoArancel() {
         this.arancelCodigo = ''
         this.seccion = ''
         this.periodoDeCiclo = ''
         this.arancelCantidad = false
         this.arancelDescripcion = ''
         this.arancelReqFacultad = false
         this.decanatoCodigo = ''
         this.materiaCodigo = ''
         this.detallePagoArancel = []
         this.idTransaccion = ''
         this.totalPagar = ''
         this.itemCantidad = 1
         this.itemPrecio = ''
         this.itemCodigo = ''
         this.dialogPagoArancel = false
      },
      // Selector de color de chips de estado en datatable
      getColorEstado(item) {
         if (
            this.toISOLocal(new Date()).substr(0, 10) >
               this.parseDate(item.fechaVencimiento) &&
            !item.facturado
         ) {
            return 'red'
         } else {
            if (item.facturado) {
               return 'green'
            } else {
               return 'blue'
            }
         }
      },
      // Selector de estados en datatable
      getStatus(item) {
         if (
            this.toISOLocal(new Date()).substr(0, 10) >
            this.parseDate(item.fechaVencimiento)
         ) {
            return 'En mora'
         } else {
            return 'Pendiente'
         }
      },
      // Listar aranceles en datatable
      listarAranceles() {
         this.obtenerEstadoSistema()
         this.detallesAranceles = []

         // SI ES CUALQUIER OTRO ARANCEL BUSCAR EN ARREGLO OBTENIDO
         this.headersAraFiltered = this.headersAra
         this.detallesAranceles = []
         this.detallesAranceles.push(
            this.cmbArancel.find((obj) => obj.codigo == this.selectArancel)
         )
         this.loadingAranceles = false
      },
      getDesc() {
         let desc
         for (let i = 0; i < this.cmbArancel.length; i++) {
            if (this.selectArancel == this.cmbArancel[i].codigo) {
               desc = this.cmbArancel[i].nombre
               return desc
            }
         }
      },
      getPrecio() {
         let precio
         for (let i = 0; i < this.cmbArancel.length; i++) {
            if (this.selectArancel == this.cmbArancel[i].codigo) {
               precio = this.cmbArancel[i].precio
               return precio
            }
         }
      },
      listarProgramas(idiomaId) {
         this.cmbProgramas = []
         axios
            .get('api/ProgramaIdioma?activo=true&idiomaId=' + idiomaId)
            .then((response) => {
               this.cmbProgramas = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      },
      validOtroArancel() {
         if (this.selectArancel != '03-021' && this.selectArancel != '03-120') {
            this.selectPrograma = null
            return true
         } else if (
            this.selectPrograma != null &&
            (this.selectArancel == '03-021' || this.selectArancel == '03-120')
         ) {
            return true
         } else {
            return true
         }
      }
   }
}
</script>
