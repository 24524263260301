<template>
   <v-dialog v-model="show" max-width="550px" persistent>
      <v-layout align-start>
         <v-flex xs12>
            <v-snackbar
               v-model="snackbar"
               :color="snackColor"
               :right="true"
               :bottom="true"
               :timeout="4000"
            >
               {{ snackText }}
               <template #action="{ attrs }">
                  <v-btn
                     color="white"
                     text
                     v-bind="attrs"
                     @click="snackbar = false"
                  >
                     Cerrar
                  </v-btn>
               </template>
            </v-snackbar>

            <!-- DIALOG CONFIRMACION -->
            <v-dialog v-model="dialogConfirmacion" persistent max-width="500px">
               <v-form ref="formEliminarAbono">
                  <v-card>
                     <v-card-text
                        class="text--primary"
                        style="margin-top: -20px"
                     >
                        <v-container grid-list-md>
                           <v-layout wrap>
                              <v-flex xs12>
                                 <span class="text-h5 font-weight-medium"
                                    >Confirmación de registro</span
                                 >
                              </v-flex>
                              <v-flex
                                 xs12
                                 class="font-weight-medium text-justify py-8"
                                 :style="
                                    $vuetify.breakpoint.smAndUp
                                       ? 'font-size: 16px;'
                                       : 'font-size: 15px;'
                                 "
                              >
                                 <span
                                    >Está por registrarse en el
                                    <span class="colorPrimary">nivel 1</span>
                                    del idioma
                                    <span class="colorPrimary">{{
                                       idiomaNombre.toLowerCase()
                                    }}</span>
                                    en el programa
                                    <span class="colorPrimary">{{
                                       programaNombre
                                    }}</span
                                    >. ¿Desea continuar?</span
                                 >
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </v-card-text>

                     <v-card-actions
                        style="margin-top: -30px; padding-bottom: 20px"
                     >
                        <v-spacer></v-spacer>
                        <v-btn width="100" @click.native="closeConfirmacion">
                           No
                        </v-btn>
                        <v-btn
                           width="100"
                           color="primary white--text"
                           :disabled="!validRegistro || disableEnviar"
                           @click.native="save"
                        >
                           Si
                        </v-btn>
                        <v-spacer></v-spacer>
                     </v-card-actions>
                  </v-card>
               </v-form>
            </v-dialog>

            <v-card elevation="0">
               <v-toolbar color="primary" dark>
                  <v-toolbar-title style="font-weight: 500">
                     <span> Registro de módulo de idiomas </span>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
               </v-toolbar>

               <v-form
                  ref="formRegistro"
                  v-model="validRegistro"
                  lazy-validation
                  mt-12
               >
                  <!-- DIALOG PARA REGISTRARSE -->
                  <v-card>
                     <v-card-text class="text--primary">
                        <v-container grid-list-md>
                           <v-layout wrap>
                              <v-flex
                                 xs12
                                 class="font-weight-medium text-justify"
                                 :style="
                                    $vuetify.breakpoint.smAndUp
                                       ? 'font-size: 16px;'
                                       : 'font-size: 15px;'
                                 "
                              >
                                 <span>
                                    <span class="colorPrimary"
                                       >IMPORTANTE:</span
                                    >
                                    Usted se está registrando en el
                                    <span class="colorPrimary">nivel 1</span>
                                    del módulo seleccionado, el cual es válido
                                    para la sede de:
                                    <span class="colorPrimary">
                                       {{
                                          sede == 'IL'
                                             ? 'ILOBASCO'
                                             : 'SANTA ANA'
                                       }} </span
                                    >.<br /><br />
                                    Debido a la implementación de medidas de
                                    bioseguridad,
                                    <span class="colorPrimary"
                                       >los cupos para los horarios del nivel 1
                                       son limitados</span
                                    >, por lo que es posible que al momento de
                                    intentar realizar su pago el sistema le
                                    indique que el cupo de este se encuentra
                                    agotado.
                                 </span>
                              </v-flex>
                           </v-layout>
                           <v-layout wrap>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="nombresInner"
                                    :rules="rulesNombre"
                                    color="primary"
                                    label="Nombres"
                                    readonly
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="apellidosInner"
                                    :rules="rulesApellido"
                                    color="primary"
                                    label="Apellidos"
                                    readonly
                                 ></v-text-field>
                              </v-flex>
                              <v-flex :class="externo ? 'xs12 sm6' : 'xs12'">
                                 <v-text-field
                                    v-model="telefonoInner"
                                    v-mask="'####-####'"
                                    :rules="rulesTelefono"
                                    color="primary"
                                    label="Teléfono"
                                    :readonly="externo"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex
                                 v-if="externo"
                                 xs12
                                 sm6
                                 class="layout justify-center"
                              >
                                 <v-checkbox
                                    v-model="checkExAlumno"
                                    label="Soy ex-alumno/na"
                                    color="primary"
                                 ></v-checkbox>
                              </v-flex>
                              <v-flex v-if="checkExAlumno" xs12>
                                 <v-card
                                    class="borderDetallesPrimary"
                                    style="
                                       margin-bottom: 20px;
                                       margin-top: 10px;
                                    "
                                 >
                                    <v-layout wrap class="px-4 py-4">
                                       <v-flex
                                          xs12
                                          class="font-weight-medium text-justify"
                                          :style="
                                             $vuetify.breakpoint.smAndUp
                                                ? 'font-size: 16px;'
                                                : 'font-size: 15px;'
                                          "
                                       >
                                          Por favor ingrese su número de DUI y
                                          Carnet. Dichos datos se utilizarán
                                          para verificar su estado de ex-alumno.
                                       </v-flex>
                                       <v-flex xs12 sm6>
                                          <v-text-field
                                             v-model="dui"
                                             v-mask="'########-#'"
                                             :rules="rulesDui"
                                             color="primary"
                                             label="DUI"
                                          ></v-text-field>
                                       </v-flex>
                                       <v-flex xs12 sm6>
                                          <v-text-field
                                             v-model="carnetInner"
                                             v-mask="'####-AA-###'"
                                             :rules="rulesCarnet"
                                             color="primary"
                                             label="Carnet"
                                          ></v-text-field>
                                       </v-flex>
                                    </v-layout>
                                 </v-card>
                              </v-flex>
                              <v-flex
                                 xs12
                                 class="font-weight-medium text-justify"
                                 :style="
                                    $vuetify.breakpoint.smAndUp
                                       ? 'font-size: 16px;'
                                       : 'font-size: 15px;'
                                 "
                              >
                                 <span style="color: black" class="mt-2">
                                    A continuación selecciona el idioma y él
                                    programa que deseas cursar.
                                 </span>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-autocomplete
                                    v-model="selectIdioma"
                                    :items="cmbIdiomas"
                                    :rules="rulesIdioma"
                                    color="primary"
                                    item-text="nombre"
                                    item-value="idiomaId"
                                    label="Idioma"
                                 >
                                 </v-autocomplete>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-autocomplete
                                    v-model="selectPrograma"
                                    :items="cmbProgramas"
                                    :rules="rulesPrograma"
                                    no-data-text="Seleccione un idioma"
                                    color="primary"
                                    item-text="nombre"
                                    item-value="codigo"
                                    label="Programa"
                                 >
                                 </v-autocomplete>
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </v-card-text>

                     <v-card-actions
                        style="
                           margin-top: -20px;
                           margin-right: 20px;
                           padding-bottom: 20px;
                        "
                     >
                        <v-spacer></v-spacer>
                        <v-btn @click.native="close"> Cancelar </v-btn>
                        <v-btn
                           color="primary white--text"
                           :disabled="!validRegistro || disableEnviar"
                           @click.native="showConfirmacion"
                        >
                           Registrarse
                        </v-btn>
                        <v-spacer></v-spacer>
                     </v-card-actions>
                  </v-card>
               </v-form>
            </v-card>
         </v-flex>
      </v-layout>
   </v-dialog>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import formatters from '../mixins/formattersLib'
import { mask } from 'vue-the-mask'

export default {
   name: 'RegistroIdiomas',
   directives: {
      mask
   },
   mixins: [formatters],
   props: {
      nombres: {
         type: String,
         default: ''
      },
      apellidos: {
         type: String,
         default: ''
      },
      telefono: {
         type: String,
         default: ''
      },
      carnet: {
         type: String,
         default: ''
      },
      externo: {
         type: Boolean,
         default: false
      },
      carnetExterno: {
         type: String,
         default: ''
      },
      value: Boolean
   },
   data: () => ({
      // Variables
      apellidosInner: '',
      carnetInner: '',
      checkExAlumno: false,
      cmbIdiomas: [],
      cmbProgramas: [],
      dialogConfirmacion: false,
      disableEnviar: false,
      dui: '',
      nombresInner: '',
      pagination: {},
      responsable: '',
      sede: process.env.VUE_APP_SEDE,
      selectIdioma: '',
      selectPrograma: '',
      snackColor: 'primary',
      snackText: '',
      snackbar: false,
      telefonoInner: '',
      validRegistro: true,

      // Reglas de validacion de formularios
      rulesNombre: [(v) => !!v || 'Ingrese sus nombres'],
      rulesApellido: [(v) => !!v || 'Ingrese sus apellidos'],
      rulesCarnet: [(v) => !!v || 'Ingrese su código de carnet'],
      rulesDui: [
         (v) => !!v || 'Ingrese su DUI',
         (v) => (v && v.length == 10) || 'Termine de escribir su DUI'
      ],
      rulesTelefono: [
         (v) => !!v || 'Ingrese su numero de teléfono',
         (v) => (v && v.length == 9) || 'Termine de escribir su teléfono'
      ],
      rulesIdioma: [(v) => !!v || 'Seleccione un idioma'],
      rulesPrograma: [(v) => !!v || 'Seleccione un programa']
   }),
   computed: {
      ...authComputed,
      show: {
         get() {
            return this.value
         },
         set(value) {
            this.$emit('input', value)
         }
      },
      idiomaNombre() {
         if (this.selectIdioma != '' && this.cmbIdiomas.length > 0) {
            return this.cmbIdiomas.find(
               (obj) => obj.idiomaId == this.selectIdioma
            ).nombre
         } else {
            return ''
         }
      },
      programaNombre() {
         if (this.selectPrograma != '' && this.cmbProgramas.length > 0) {
            return this.cmbProgramas.find(
               (obj) => obj.codigo == this.selectPrograma
            ).nombre
         } else {
            return ''
         }
      }
   },
   watch: {
      selectIdioma(val) {
         this.listarProgramas(val)
      },
      show(val) {
         if (val) {
            this.nombresInner = this.nombres.trim()
            this.apellidosInner = this.apellidos.trim()
            this.carnetInner = this.carnet.trim()
            if (this.externo) {
               this.telefonoInner = this.telefono
            }
            this.listarComboboxes()
         }
      }
   },
   created() {},
   methods: {
      showConfirmacion() {
         if (this.$refs.formRegistro.validate()) {
            this.dialogConfirmacion = true
         }
      },
      closeConfirmacion() {
         this.dialogConfirmacion = false
      },
      listarProgramas(idiomaId) {
         this.cmbProgramas = []
         axios
            .get('api/ProgramaIdioma?activo=true&idiomaId=' + idiomaId)
            .then((response) => {
               this.cmbProgramas = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      },
      listarComboboxes() {
         axios.get('api/general/idioma?defaultValues=true').then((response) => {
            this.cmbIdiomas = response.data
         })
      },
      close() {
         this.reset()
         this.disableEnviar = false
         this.show = false
      },
      save() {
         if (this.$refs.formRegistro.validate()) {
            this.disableEnviar = true
            // Registrar
            axios
               .post('api/ProgramaIdioma/registrar', {
                  nombres: this.nombresInner,
                  apellidos: this.apellidosInner,
                  telefono: this.telefonoInner,
                  dui: this.dui,
                  carnet: this.carnetInner,
                  idioma: this.selectIdioma,
                  programa: this.selectPrograma,
                  esExterno: this.externo,
                  carnetExterno: this.carnetExterno
               })
               .then((response) => {
                  this.snackText = 'Registro de módulo realizado exitosamente!'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true
                  this.closeConfirmacion()

                  // Retornar identidad a componente padre
                  this.$emit('carnet-generado', response.data)
                  this.close()
               })
               .catch(() => {
                  this.snackText = 'Algo ha ido mal...'
                  this.snackColor = 'snackError'
                  this.snackbar = true
                  this.disableEnviar = false
               })
         }
      },
      // Limpiar formularios y componentes
      reset() {
         try {
            this.$refs.formRegistro.reset()
         } catch (error) {
            //Do nothing
         }
         this.nombresInner = ''
         this.apellidosInner = ''
         this.carnetInner = ''
         this.selectIdioma = ''
         this.selectPrograma = ''
      }
   }
}
</script>
